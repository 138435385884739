<p-toolbar>
  <ng-template pTemplate="start">
    <div class="d-flex">
      <span class="toggle-btn" (click)="onToggleSidebar()" aria-hidden="true"
        ><i class="pi pi-bars"></i
      ></span>
      <a href="/">
        <img
          [src]="
            bucketUrl + this.orgLogoUrl$()
              ? this.orgLogoUrl$()
              : '/images/regenesis-icon-light.png'
          "
          alt="Logo"
          class="w-12 h-auto ml-[15px] mr-[15px]" />
      </a>
      <p class="ml-5">
        Welcome back, {{ user$()?.firstName }} {{ user$()?.lastName }}
      </p>
    </div>
  </ng-template>
  <ng-template pTemplate="item" let-item let-root="root"> </ng-template>
  <ng-template pTemplate="end">
    <div class="d-flex">
      <div class="header-icons">
        <i class="pi pi-th-large"></i>
        <i class="pi pi-question-circle"></i>
        <i class="pi pi-bell"></i>
      </div>
      <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
      <img
        aria-hidden="true"
        [src]="user$()?.photo ?? bucketUrl + '/images/avatar.png'"
        alt="avatar"
        class="w-10 h-10 avatar ml-10"
        (click)="menu.toggle($event)" />
    </div>
  </ng-template>
</p-toolbar>
