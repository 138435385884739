import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-heat-map',
  standalone: true,
  imports: [GoogleMapsModule, CommonModule],
  templateUrl: './heat-map.component.html',
  styleUrl: './heat-map.component.scss',
})
export class HeatMapComponent<T> implements OnChanges {
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) data: T | null = null;
  @Input() overlay: boolean = false;
  @Output() reload = new EventEmitter<any>();

  mapOptions = {
    zoomControl: true,
    zoom: 2, // Default zoom level to show the whole world
    center: { lat: 0, lng: 0 }, // Center the map to show the whole world
  };
  heatmapLayer!: google.maps.visualization.HeatmapLayer;
  map!: google.maps.Map;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data) {
        this.initializeHeatmap();
      }
    }
  }

  onMapReady(map: google.maps.Map): void {
    this.map = map;
    this.initializeHeatmap();
  }

  initializeHeatmap(): void {
    if (!this.map) {
      return;
    }
    const validData = Array.isArray(this.data) ? this.data : [];
    if (!validData || validData.length === 0) {
      if (this.heatmapLayer) {
        this.heatmapLayer.setMap(null); // Remove the heatmap layer from the map
      }
      this.map.setOptions({
        zoom: this.mapOptions.zoom, // Reset zoom to default
        center: this.mapOptions.center, // Reset center to default
      });
      return;
    }
    let gradient: any[] = [];
    const heatmapData: google.maps.visualization.WeightedLocation[] =
      validData.map(item => {
        const latitude = parseFloat(item.latitude); // Use item.latitude, as it's a string in the data
        const longitude = parseFloat(item.longitude); // Use item.longitude, as it's a string in the data
        const safe = item.safe || 0;
        const expiring = item.expiring || 0;
        const expired = item.expired || 0;

        if (expired !== 0) {
          gradient.push({
            value: expired,
            color: 'rgba(220, 38, 38, 1)',
          });
        } else {
          gradient.push({
            value: expired,
            color: 'rgba(220, 38, 38, 0)',
          });
        }
        if (safe !== 0) {
          gradient.push({
            value: safe,
            color: 'rgba(22, 163, 74, 1)',
          });
        } else {
          gradient.push({
            value: safe,
            color: 'rgba(22, 163, 74, 0)',
          });
        }
        if (expiring !== 0) {
          gradient.push({
            value: expiring,
            color: 'rgba(253, 224, 71, 1)',
          });
        } else {
          gradient.push({
            value: expiring,
            color: 'rgba(253, 224, 71, 0)',
          });
        }

        // Now, generate the heatmap data with location and calculated weight
        return {
          location: new google.maps.LatLng(latitude, longitude),
          weight: 1,
        };
      });

    if (this.heatmapLayer) {
      this.heatmapLayer.setMap(null);
    }

    const totalValues = validData.map(
      d => d.safe + d.expiring + d.expired || 0
    );
    const maxTotal = Math.max(...totalValues);
    const calculatedRadius = Math.min(10 + Math.log1p(maxTotal) * 10, 50);

    this.heatmapLayer = new google.maps.visualization.HeatmapLayer({
      data: heatmapData,
      radius: calculatedRadius,
      opacity: 0.8,
      gradient: (() => {
        const sortedGradient = gradient
          .sort((a, b) => b.value - a.value)
          .map(g => g.color);
        sortedGradient.unshift('rgba(0, 0, 0, 0)');
        return sortedGradient;
      })(),
    });

    this.heatmapLayer.setMap(this.map);
  }
}
