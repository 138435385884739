<div>
  <div class="flex items-center justify-between mt-2 mb-2">
    <h3>Track Map</h3>
  </div>

  <div>
    <google-map
      height="500px"
      width="100%"
      [options]="mapOptions"
      (mapInitialized)="onMapReady($event)">
      <map-polyline [path]="polylinePath" [options]="polylineOptions">
      </map-polyline>
    </google-map>
  </div>
</div>
