import {
  Component,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ExpirationAnalysis } from '../../../../store/dashboard-store/dashboard.model';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-donut-chart',
  imports: [CommonModule],
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss',
})
export class DonutChartComponent implements OnDestroy, OnChanges {
  @Input({ required: true }) data: ExpirationAnalysis | null = null;
  @Input({ required: true }) title: string = '';
  @Input() overlay: boolean = false;
  private root!: am5.Root;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.initMap();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('donutChart');

    this.root.setThemes([am5themes_Animated.new(this.root)]);

    const chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
        innerRadius: am5.percent(50), // Make it a donut chart
      })
    );

    if (this.data) {
      const chartData = [
        { category: 'Safe', value: this.data.safe }, // Green
        { category: 'Expired', value: this.data.expired }, // Red
        { category: 'Expiring', value: this.data.expiring }, // Yellow
      ].filter(item => item.value > 0);

      const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

      const processedData = chartData.map(item => ({
        ...item,
        percentage: totalValue > 0 ? (item.value / totalValue) * 100 : 0,
      }));

      series.data.setAll(processedData);

      const colorMapping: Record<string, am5.Color> = {
        Safe: am5.color('#16A34A'), // Green
        Expired: am5.color('#DC2626'), // Red
        Expiring: am5.color('#FDE047'), // Yellow
      };

      series.slices.each(slice => {
        const data: any = slice.dataItem?.dataContext;
        const color = colorMapping[data.category];
        if (color) {
          slice.set('fill', color);
          slice.set('stroke', am5.color('#FFFFFF'));
          slice.set('strokeWidth', 5);
          slice.set('strokeOpacity', 0.8);
          slice.set('cornerRadius', 50);
        }
      });
    }

    series.labels.template.setAll({
      fontSize: 12,
      text: "{percentage.formatNumber('0.0')}%",
      textType: 'circular',
      inside: true,
      radius: 10,
      fill: am5.color(0xffffff),
    });

    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: this.root.horizontalLayout,
      })
    );

    legend.labels.template.setAll({
      text: '{category}', // Only display category
      fontSize: 12,
      fill: am5.color('#000000'), // Set legend text color
    });

    legend.valueLabels.template.setAll({
      text: '{value}', // Show original value
      fontSize: 12,
      fill: am5.color('#000000'), // Set value text color
    });

    legend.data.setAll(series.dataItems);

    // Animation
    series.appear(1000, 100);
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
