<div>
  <div class="flex items-center justify-between mt-2 mb-2">
    <h3>Heat Map</h3>
  </div>
  <div class="relative">
    <div
      [ngClass]="{
        'pointer-events-none': !overlay,
      }">
      <google-map
        [ngClass]="{
          'opacity-25': !overlay,
        }"
        height="500px"
        width="100%"
        [options]="mapOptions"
        (mapInitialized)="onMapReady($event)">
      </google-map>
    </div>

    @if (!overlay) {
      <div class="absolute inset-0 flex items-center justify-center">
        <div
          class="bg-primary-color text-white text-sm font-bold px-4 py-2 rounded shadow-lg">
          Select a Product
        </div>
      </div>
    }
  </div>
</div>
