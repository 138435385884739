import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';
import {
  AddProductParams,
  AddProductResponse,
  BulkUpdateProductTemplateComponentParams,
  BulkUpdateProductTemplateModulesParams,
  ProductDesignTemplatePageModuleDataContent,
  ProductItemDataResponse,
  ProductKdeValuesResponse,
  ProductResponse,
  ProductsResponse,
  UpdateProductCTEParams,
  UpdateProductParams,
  UpdateProductQrSettingsParams,
  UpdateProductTemplateModuleParams,
  UpdateProductTemplateParams,
} from './products.model';
import { OrganizationKDEsParams } from '../organizations-store/organizations.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getProducts = (
    paginationParams: PaginationParams
  ): Observable<ProductsResponse> => {
    return this.http
      .get<ProductsResponse>(`${this.apiUrl}/api/products`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching products: ' + error.message)
          );
        })
      );
  };

  public getProductItemData = (
    paginationParams: PaginationParams
  ): Observable<ProductItemDataResponse> => {
    return this.http
      .get<ProductItemDataResponse>(`${this.apiUrl}/api/qr-product-data`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () =>
              new Error('Error fetching product item data: ' + error.message)
          );
        })
      );
  };

  public deleteProduct(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/api/products/${id}`).pipe(
      map(response => response),
      catchError(error => {
        return throwError(
          () => new Error('Error deleting product: ' + error.message)
        );
      })
    );
  }

  public deleteProductItemData(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/qr-product-data/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () =>
              new Error('Error deleting product item data: ' + error.message)
          );
        })
      );
  }

  public deleteProductKDE(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/products/kdes/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting product: ' + error.message)
          );
        })
      );
  }

  public getProductKdeValues(
    kdeId: number
  ): Observable<ProductKdeValuesResponse> {
    return this.http
      .get<ProductKdeValuesResponse>(
        `${this.apiUrl}/api/qr-product-data/kde/${kdeId}/values`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching KDE values: ' + error.message)
          );
        })
      );
  }

  public deleteProductCTE(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/products/ctes/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting product: ' + error.message)
          );
        })
      );
  }

  public addProductKDE(payload: any): Observable<void> {
    delete (payload as any).id;
    return this.http
      .post<void>(`${this.apiUrl}/api/products/kdes/`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateProductKDE(id: any, payload: any): Observable<void> {
    delete (payload as any).id;
    return this.http
      .put<void>(`${this.apiUrl}/api/products/kdes/${id}`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateProductCTE(
    id: number,
    payload: UpdateProductCTEParams
  ): Observable<void> {
    delete (payload as any).id;
    return this.http
      .put<void>(`${this.apiUrl}/api/products/ctes/${id}`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public addProduct = (
    payload: AddProductParams
  ): Observable<AddProductResponse> => {
    return this.http
      .post<AddProductResponse>(`${this.apiUrl}/api/products`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public getProduct = (id: number): Observable<ProductResponse> => {
    return this.http
      .get<ProductResponse>(`${this.apiUrl}/api/products/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching products: ' + error.message)
          );
        })
      );
  };

  public updateProduct = (
    id: number,
    payload: UpdateProductParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .put<GenericApiResponse>(`${this.apiUrl}/api/products/${id}`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public updateProductKDEs(payload: OrganizationKDEsParams): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/products/kdes/bulk`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating organization KDEs');
          });
        })
      );
  }

  public updateProductQrSettings(
    id: number,
    payload: UpdateProductQrSettingsParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/product-qr-settings/${id}`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating Product QR Settings');
          });
        })
      );
  }

  public updateProductTemplate(
    id: number,
    payload: UpdateProductTemplateParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/product-design-templates/${id}`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating Product template');
          });
        })
      );
  }

  public bulkUpdateProductTemplateModules(
    payload: BulkUpdateProductTemplateModulesParams
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.apiUrl}/api/page-module-template-data/bulk-update-order`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating Product template modules');
          });
        })
      );
  }

  public bulkUpdateProductTemplateComponents(
    payload: BulkUpdateProductTemplateComponentParams[]
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.apiUrl}/api/page-module-template-data-content/bulk-update-order`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating Product template components');
          });
        })
      );
  }

  public addProductTemplateModule(
    productId: number,
    id: number
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.apiUrl}/api/page-module-template-data/${productId}/${id}/create`,
        {}
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error adding product template module');
          });
        })
      );
  }

  public updateProductTemplateModule(
    id: number,
    payload: UpdateProductTemplateModuleParams
  ): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/page-module-template-data/${id}`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating product template module');
          });
        })
      );
  }

  public deleteProductTemplateModule(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/api/page-module-template-data/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error deleting product template module');
          });
        })
      );
  }

  public deleteProductTemplateComponent(id: number): Observable<void> {
    return this.http
      .delete<void>(
        `${this.apiUrl}/api/page-module-template-data-content/${id}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error deleting product template component');
          });
        })
      );
  }

  public updateProductTemplateComponent(
    id: number,
    payload: ProductDesignTemplatePageModuleDataContent
  ): Observable<void> {
    return this.http
      .put<void>(
        `${this.apiUrl}/api/page-module-template-data-content/${id}`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating product template component');
          });
        })
      );
  }

  public addProductTemplateComponent(
    payload: ProductDesignTemplatePageModuleDataContent
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.apiUrl}/api/page-module-template-data-content`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error adding product template component');
          });
        })
      );
  }
}
