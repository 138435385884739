<div class="w-full p-8 bg-[#FAFAFA]">
  <div class="grid gap-4">
    <div class="flex items-center justify-between mb-5">
      <h3>Track Products</h3>
      <p-button [outlined]="true"
        ><span class="pi pi-download mr-3 pl-4"></span
        ><span class="pr-4">Export CSV</span></p-button
      >
    </div>

    <app-dashboard-filters
      (selectedFilters)="setFilters($event)"></app-dashboard-filters>

    <app-track-map [points]="productLocationStats$()"></app-track-map>
    <app-gantt-chart
      [dataset]="
        productId !== 0 ? trackDashboardData$() : []
      "></app-gantt-chart>

    <app-cluster-columns
      [dataset]="
        productId !== 0 ? trackDashboardData$() : []
      "></app-cluster-columns>
    <div class="mt-6">
      <div
        class="bg-white rounded-xl w-full h-[15vh] p-5 overflow-hidden relative flex items-center justify-between">
        <div class="flex items-center">
          <img src="assets/reports.png" alt="Image" class="w-40 mr-4" />
          <div>
            <h3>For Trace an Item Level Product</h3>
            <p class="text-[#A7A9AC]">Click on button to move there!</p>
          </div>
        </div>
        <p-button>
          <span class="pr-2">Trace Dashboard</span>
          <span class="pi pi-arrow-right mr-3 pl-2"></span>
        </p-button>
      </div>
    </div>
  </div>
</div>
