import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  Product,
  ProductKdeValues,
} from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import {
  GetAllProducts,
  GetProductKdeValues,
  SearchProducts,
} from '../../../../store/products-store/products.actions';
import { CalendarModule } from 'primeng/calendar';
import {
  GetCities,
  GetCountries,
} from '../../../../store/general-store/general.actions';
import { City, Country } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ButtonModule } from 'primeng/button';
import { format } from 'date-fns';
import { DashboardFilter } from '../../../../store/dashboard-store/dashboard.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dashboard-filters',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    CalendarModule,
    ButtonModule,
    CommonModule,
  ],
  templateUrl: './dashboard-filters.component.html',
  styleUrl: './dashboard-filters.component.scss',
})
export class DashboardFiltersComponent implements OnInit {
  @Output() selectedFilters = new EventEmitter();
  searchedProducts$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.searchProducts
  );
  products$ = this.store.selectSignal(ProductStateSelectors.getAllProducts);
  productStateLoading$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );
  countries$: Signal<Country[]> = this.store.selectSignal(
    GeneralStateSelectors.getCountries
  );
  cities$: Signal<City[]> = this.store.selectSignal(
    GeneralStateSelectors.getCities
  );
  productKdeValues$: Signal<ProductKdeValues | null> = this.store.selectSignal(
    ProductStateSelectors.getProductKdeValues
  );

  public filters: DashboardFilter = {
    product: null,
    dateRange: [],
    startDate: null,
    endDate: null,
    cte: null,
    kde: null,
    kdeValue: null,
  };

  constructor(private store: Store) {
    this.getCountries();
    this.getAllProducts();
  }

  ngOnInit(): void {}

  loadData(dropdown: any = null) {
    if (dropdown) {
      dropdown.filterValue = '';
    }
  }

  getCities(country: Country) {
    this.store.dispatch(new GetCities(country.id));
  }

  getCountries() {
    this.store.dispatch(new GetCountries());
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  setDateFilter(event: any) {
    if (event && event[0] !== null) {
      this.filters.startDate = format(event[0], 'dd/MM/yyyy');
    }
    if (event && event[1] !== null) {
      this.filters.endDate = format(event[1], 'dd/MM/yyyy');
    }
  }

  getProductKdeValues(kdeId: number) {
    this.store.dispatch(new GetProductKdeValues(kdeId));
  }

  applyFilter() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    const selectedFiltersProcessed: any = {};
    Object.keys(selectedFilters).forEach(key => {
      if (key === 'kde') {
        selectedFiltersProcessed['kdeId'] = selectedFilters[key].id;
        if (selectedFilters[key]) {
          this.getProductKdeValues(selectedFilters[key].id);
        }
      }
      if (key === 'cte') {
        selectedFiltersProcessed['cteId'] = selectedFilters[key].id;
      }
      if (key === 'product') {
        selectedFiltersProcessed['productId'] = selectedFilters[key].id;
      }
      if (key === 'kdeValue') {
        selectedFiltersProcessed['kdeValue'] = selectedFilters[key];
      }
      if (key === 'dateRange') {
        if (
          selectedFilters[key] &&
          selectedFilters[key][0] &&
          selectedFilters[key][1]
        ) {
          selectedFiltersProcessed['startDate'] = format(
            selectedFilters[key][0],
            'yyyy-MM-dd'
          );
          selectedFiltersProcessed['endDate'] = format(
            selectedFilters[key][1],
            'yyyy-MM-dd'
          );
        }
      }
    });
    this.selectedFilters.emit(selectedFiltersProcessed);
  }
}
