<p-table
  #dt
  [columns]="[]"
  [value]="isProcessing$() ? skeleton : data$()"
  [paginator]="!isProcessing$()"
  [rows]="pagination$().itemsPerPage"
  [first]="(pagination$().currentPage - 1) * pagination$().itemsPerPage"
  [totalRecords]="pagination$().totalItems"
  [lazy]="true"
  [showCurrentPageReport]="true"
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines"
  [resizableColumns]="true"
  [tableStyle]="{ 'min-width': '50rem' }"
  [exportHeader]="'customExportHeader'"
  [rowHover]="true"
  dataKey="id"
  [currentPageReportTemplate]="
    isProcessing$() ? '' : 'Showing {first} to {last} of {totalRecords} entries'
  "
  (onPage)="pageChange($event)"
  [globalFilterFields]="getFilterableHeaders()"
  (onSort)="sort($event)"
  [rowsPerPageOptions]="[5, 10, 25, 50]">
  @if (showTableToolbar) {
    <ng-template pTemplate="caption">
      <div>
        <p-button
          icon="pi pi-external-link"
          label="Export"
          (onClick)="exportData()" />
      </div>
      <div class="filters flex justify-between">
        <p-multiSelect
          class="headers"
          [filter]="false"
          [(ngModel)]="visibleHeaders"
          [options]="headers$()"
          optionLabel="label"
          placeholder="Choose Columns"
          (onChange)="handleSelection()">
          <ng-template let-value pTemplate="selectedItems">
            <div><i class="pi pi-plus"></i></div>
          </ng-template>
          <ng-template let-header pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ header.label }}</div>
            </div>
          </ng-template>
        </p-multiSelect>
        <p-button
          icon="pi pi-filter-slash"
          class="ml-3"
          label="Clear Filters"
          (onClick)="clearAllFilters()" />
        <p-iconField iconPosition="left" class="ml-3">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>
          <input
            [formControl]="search"
            pInputText
            type="text"
            class="w-72 filter-input"
            placeholder="Search" />
        </p-iconField>
      </div>
    </ng-template>
  }
  <ng-template pTemplate="header">
    @if (visibleHeaders.length === 0) {
      <tr>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
      </tr>
      <tr>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
      </tr>
      <tr>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
      </tr>
      <tr>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
      </tr>
      <tr>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
        <td><p-skeleton /></td>
      </tr>
    } @else {
      <tr>
        @for (header of visibleHeaders; track header.key) {
          @if (header.key !== 'actions' || showActions) {
            <th
              [id]="header.key"
              [pSortableColumn]="
                header.sortable && allowSorting ? header.key : ''
              "
              pResizableColumn
              style="min-width: 14rem">
              <div class="flex justify-content-between items-center">
                {{ header.label }}
                @if (header.sortable && allowSorting) {
                  <p-sortIcon [field]="header.key" />
                }
                @if (header.filterable && allowFiltering) {
                  <p-columnFilter
                    #cf
                    [field]="header.key"
                    matchMode="in"
                    display="menu"
                    [showMatchModes]="false"
                    [showOperator]="false"
                    [showAddButton]="false"
                    [showClearButton]="false"
                    [showApplyButton]="false"
                    [class.selected-filter]="selectedFilters[header.key]">
                    <ng-template pTemplate="header">
                      <div class="px-3 pt-3 pb-0">
                        <span class="font-bold">{{ header.label }}</span>
                      </div>
                    </ng-template>
                    <ng-template
                      pTemplate="filter"
                      let-value
                      let-filter="filterCallback">
                      <p-button (click)="cf.hide(); refresh()"
                        >Apply & Close</p-button
                      >
                      <p-multiSelect
                        class="filters"
                        [(ngModel)]="selectedFilters[header.key]"
                        [options]="header.filterValues"
                        placeholder="All"
                        [filter]="false"
                        (onChange)="selectFilters($event.value, header.key)">
                        <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                            <span class="ml-1 mt-1">{{
                              option | textTransform
                            }}</span>
                          </div>
                        </ng-template>
                      </p-multiSelect>
                    </ng-template>
                  </p-columnFilter>
                }
              </div>
            </th>
          }
        }
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="body" let-data>
    @if (isProcessing$()) {
      <tr>
        @for (column of visibleHeaders; track column) {
          <td><p-skeleton /></td>
        }
      </tr>
    } @else {
      <tr>
        @for (column of visibleHeaders; track column) {
          @if (column.key === 'actions') {
            @if (showActions) {
              <td class="table-actions-column" id="table-actions">
                @for (action of data[column.key]; track action) {
                  @if (
                    allowedActions.length === 0 ||
                    allowedActions.includes(action)
                  ) {
                    <app-table-action-button
                      [actionType]="action"
                      (actionClicked)="actionClicked($event, data.id, data)" />
                  }
                }
              </td>
            }
          } @else if (column.key === 'logo') {
            <td>
              <img
                [src]="data[column.key]"
                alt="Logo"
                class="w-[50px] h-[50px] table-image-thumbnail" />
            </td>
          } @else {
            <td>{{ data[column.key] | textTransform }}</td>
          }
        }
      </tr>
    }
  </ng-template>
</p-table>
