import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToSentence',
})
export class CamelToSentencePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Insert a space before each uppercase letter or digit
    const sentence = value
      .replace(/([A-Z])/g, ' $1') // Add a space before uppercase letters
      .replace(/(\d+)/g, ' $1') // Add a space before numbers
      .trim(); // Remove leading and trailing spaces

    // Capitalize the first letter of each word
    return sentence
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
