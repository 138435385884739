<div class="w-100 bg-white rounded-xl">
  <div class="flex items-center justify-start gap-2 p-4">
    <div class="w-[100%]">
      @if (!filters.product) {
        <p-dropdown
          [options]="products$()"
          [(ngModel)]="filters.product"
          optionLabel="productName"
          [filter]="true"
          filterBy="productName"
          placeholder="Product Passport"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-product #item>
            <div class="flex items-center gap-2">
              <div>{{ product?.productName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div
          class="flex items-center justify-between border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
          <div>
            <div class="text-xs">Product Passport</div>
            <div class="mr-3 font-bold">
              <small>{{ filters.product.productName }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.product = null;
                filters.cte = null;
                filters.kde = null;
                applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div *ngIf="filters.product" class="w-[100%]">
      @if (!filters.cte) {
        <p-dropdown
          [options]="filters!.product!.productCtes"
          [(ngModel)]="filters.cte"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          placeholder="CTE"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-cte #item>
            <div class="flex items-center gap-2">
              <div>{{ cte?.title }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div
          class="flex items-center justify-between border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
          <div>
            <div class="text-xs">CTE</div>
            <div class="mr-3 font-bold">
              <small>{{ filters!.cte!.title }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.cte = null; filters.kde = null; applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div *ngIf="filters.cte && filters.product" class="w-[100%]">
      @if (!filters.kde) {
        <p-dropdown
          [options]="filters!.cte!.productKdes"
          [(ngModel)]="filters.kde"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          placeholder="KDE"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-kde #item>
            <div class="flex items-center gap-2">
              <div>{{ kde?.title }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div
          class="flex items-center justify-between border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
          <div>
            <div class="text-xs">KDE</div>
            <div class="mr-3 font-bold">
              <small>{{ filters!.kde!.title }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.kde = null; filters.kdeValues = []; applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div *ngIf="filters.cte && filters.product && filters.kde" class="w-[100%]">
      <div class="text-xs ml-[7px] mb-[-5px]">
        {{ productKdeValues$()?.kde?.title }}
      </div>
      <p-multiSelect
        [options]="productKdeValues$()?.values"
        [(ngModel)]="filters.kdeValues"
        optionLabel="value"
        optionValue="value"
        [filter]="true"
        filterBy="value"
        [maxSelectedLabels]="1"
        class="w-full md:w-56"
        (ngModelChange)="applyFilter()">
        <ng-template let-value #item>
          <div class="flex items-center gap-2">
            <div>{{ value.value }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
</div>
