import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { catchError, tap } from 'rxjs';
import { DashboardStateModel } from './dashboard.model';
import { DashboardService } from './dashboard.service';
import {
  GetActivityLogs,
  GetChartsData,
  GetCteStatistics,
  GetDailyProductStats,
  GetDeviceAnalytics,
  GetExpirationAnalysis,
  GetLocationAnalytics,
  GetLocationExpirationStats,
  GetTrackDashboardData,
  GetProductAnalytics,
  GetQrMetrics,
  GetProductLocationStats,
  ResetDashboardState,
} from './dashboard.actions';

@State<DashboardStateModel>({
  name: 'Dashboard',
  defaults: {
    isLoading: false,
    locationExpirationStats: [],
    trackDashboardData: [],
    productAnalytics: [],
    dailyProductStats: [],
    deviceAnalytics: [],
    productStatistics: [],
    productLocationStats: [],
    qrMetrics: null,
    locationAnalytics: {
      locationStats: [],
      totalScans: 0,
      totalUniqueQrCodes: 0,
    },
    activities: {
      payload: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0,
      },
    },
    productChartData: {
      chartData: [],
      zoomCords: { lat: 0, lon: 0 },
      summary: null,
    },
    expirationAnalysis: {
      safe: 0,
      expired: 0,
      expiring: 0,
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Action(GetActivityLogs)
  getActivityLogs(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetActivityLogs
  ) {
    return this.dashboardService.getActivityLogs(payload).pipe(
      tap(response => {
        patchState({
          activities: {
            payload: response.payload,
            pagination: response.pagination,
          },
        });
      })
    );
  }
  @Action(GetChartsData)
  getProductChartData(
    { patchState }: StateContext<DashboardStateModel>,
    { productId }: GetChartsData
  ) {
    return this.dashboardService.getChartsData(productId).pipe(
      tap(response => {
        patchState({
          productChartData: response,
        });
      })
    );
  }
  @Action(GetCteStatistics)
  getCteStatistics(
    { patchState }: StateContext<DashboardStateModel>,
    { productId }: GetCteStatistics
  ) {
    return this.dashboardService.getCteStatistics(productId).pipe(
      tap(async res => {
        patchState({
          productStatistics: res.payload,
        });
      })
    );
  }
  @Action(GetExpirationAnalysis)
  getProductExpiration(
    { patchState }: StateContext<DashboardStateModel>,
    { productId, filters }: GetExpirationAnalysis
  ) {
    return this.dashboardService.getExpirationAnalysis(productId, filters).pipe(
      tap(async res => {
        patchState({
          expirationAnalysis: res.payload,
        });
      })
    );
  }
  @Action(GetQrMetrics)
  getQrMetrics(
    { patchState }: StateContext<DashboardStateModel>,
    { timeFrame, productId }: GetQrMetrics
  ) {
    return this.dashboardService.getQrMetrics(timeFrame, productId).pipe(
      tap(async res => {
        patchState({
          qrMetrics: res.payload,
        });
      })
    );
  }
  @Action(GetLocationAnalytics)
  getLocationAnalytics(
    { patchState }: StateContext<DashboardStateModel>,
    { filters }: GetLocationAnalytics
  ) {
    patchState({ isLoading: true });
    return this.dashboardService.getLocationAnalytics(filters).pipe(
      tap(async res => {
        patchState({
          isLoading: false,
          locationAnalytics: {
            locationStats: res.payload?.locationStats,
            totalScans: res.payload?.totalScans,
            totalUniqueQrCodes: res.payload?.totalUniqueQrCodes,
          },
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
      })
    );
  }
  @Action(GetDeviceAnalytics)
  getDeviceAnalytics(
    { patchState }: StateContext<DashboardStateModel>,
    { filters }: GetDeviceAnalytics
  ) {
    return this.dashboardService.getDeviceAnalytics(filters).pipe(
      tap(async res => {
        patchState({
          deviceAnalytics: res.payload,
        });
      })
    );
  }
  @Action(GetProductAnalytics)
  getProductAnalytics(
    { patchState }: StateContext<DashboardStateModel>,
    { filters }: GetProductAnalytics
  ) {
    return this.dashboardService.getProductAnalytics(filters).pipe(
      tap(async res => {
        patchState({
          productAnalytics: res.payload,
        });
      })
    );
  }
  @Action(GetDailyProductStats)
  getDailyProductStats(
    { patchState }: StateContext<DashboardStateModel>,
    { filters }: GetDailyProductStats
  ) {
    return this.dashboardService.getDailyProductStats(filters).pipe(
      tap(async res => {
        patchState({
          dailyProductStats: res.payload,
        });
      })
    );
  }
  @Action(GetLocationExpirationStats)
  getLocationExpirationStats(
    { patchState }: StateContext<DashboardStateModel>,
    { productId, filters }: GetLocationExpirationStats
  ) {
    patchState({ isLoading: true });
    return this.dashboardService
      .getLocationExpirationStats(productId, filters)
      .pipe(
        tap(async res => {
          patchState({
            isLoading: false,
            locationExpirationStats: res.payload,
          });
        }),
        catchError(async error => {
          patchState({ isLoading: false });
        })
      );
  }

  @Action(GetTrackDashboardData)
  getTrackDashboardData(
    { patchState }: StateContext<DashboardStateModel>,
    { filters, productId }: GetTrackDashboardData
  ) {
    return this.dashboardService.getTrackDashboardData(filters, productId).pipe(
      tap(async res => {
        patchState({
          trackDashboardData: res.payload,
        });
      })
    );
  }

  @Action(ResetDashboardState)
  resetDashboardState({ patchState }: StateContext<DashboardStateModel>) {
    patchState({
      locationExpirationStats: [],
      trackDashboardData: [],
      productAnalytics: [],
      dailyProductStats: [],
      deviceAnalytics: [],
      productStatistics: [],
      productLocationStats: [],
      qrMetrics: null,
      locationAnalytics: {
        locationStats: [],
        totalScans: 0,
        totalUniqueQrCodes: 0,
      },
      productChartData: {
        chartData: [],
        zoomCords: { lat: 0, lon: 0 },
        summary: null,
      },
      expirationAnalysis: {
        safe: 0,
        expired: 0,
        expiring: 0,
      },
    });
  }

  @Action(GetProductLocationStats)
  getProductLocationStats(
    { patchState }: StateContext<DashboardStateModel>,
    { filters, productId }: GetProductLocationStats
  ) {
    return this.dashboardService
      .getProductLocationStats(filters, productId)
      .pipe(
        tap(async res => {
          patchState({
            productLocationStats: res.payload,
          });
        })
      );
  }
}
