import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ProductLocationStats } from '../../../../store/dashboard-store/dashboard.model';

@Component({
  selector: 'app-track-map',
  standalone: true,
  imports: [GoogleMapsModule],
  templateUrl: './track-map.component.html',
  styleUrls: ['./track-map.component.scss'],
})
export class TrackMapComponent implements OnChanges {
  @Input({ required: true }) points: ProductLocationStats[] = [];

  map!: google.maps.Map;

  mapOptions = {
    zoomControl: true,
    zoom: 3,
    maxZoom: 15,
    minZoom: 3,
    center: { lat: 40, lng: -30 },
  };

  polylinePath: google.maps.LatLngLiteral[] = [];
  polylineOptions: google.maps.PolylineOptions = {
    strokeColor: '#000000',
    strokeOpacity: 0.5,
    strokeWeight: 3,
  };

  circles: google.maps.Circle[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['points'] &&
      changes['points'].currentValue !== changes['points'].previousValue
    ) {
      if (this.points) {
        this.initializeMap();
      }
    }
  }

  onMapReady(map: google.maps.Map) {
    this.map = map;
  }

  initializeMap() {
    this.polylinePath = this.generateCurvedPath(this.points);

    const infoWindow = new google.maps.InfoWindow();

    this.points.forEach((point, index) => {
      const circle = new google.maps.Circle({
        center: { lat: point.lat, lng: point.lng },
        radius: 50000, // Radius in meters
        fillColor: point.color,
        fillOpacity: 1.0,
        strokeWeight: 15,
        strokeOpacity: 0.5,
        strokeColor: point.color,
        map: this.map,
      });

      // Set up the content for the InfoWindow
      const contentString = `<div class='p-2 text-left w-[250px]'>
        <div class='flex justify-end'>
          <span id="${'closeButton' + index}" class='pi pi-times cursor-pointer absolute'></span>
        </div>
        <div class='flex gap-2 items-center'>
          <div class='text-white p-2 rounded-full' style="background-color: ${point.color};"><span class='pi pi-map-marker'></span></div>
          <div>
            <div><strong>${point.location}</strong></div>
            <div><small>${point.cteName}</small></div>
          </div>
        </div>
        <div class='mt-3'>
          <div><small>Volume: <strong>${point.volume}</strong></small></div>
          <div><small>Last Updated: <strong>${point.lastUpdated}</strong></small></div>
          <div class='mt-3 flex items-center gap-2'>
            <progress value="${point.progress}" max="100" class='w-[100%] h-[5px]'>100
            </progress>
            <small>${point.progress}%</small>
          </div>
        </div>
      </div>
        <style>
        /* Change the progress bar color */
        progress[value] {
          background-color: #e0e0e0; /* Background color */
        }
        progress[value]::-webkit-progress-value {
          background-color: ${point.color}; /* Color based on progress */
        }
        progress[value]::-moz-progress-bar {
          background-color: ${point.color}; /* Firefox */
        }
      </style>`;

      // Add a click event listener to the circle to open the InfoWindow
      circle.addListener('click', () => {
        infoWindow.setContent(contentString); // Set the content dynamically
        infoWindow.setPosition({ lat: point.lat, lng: point.lng }); // Set the position to the circle's location
        infoWindow.open(this.map);

        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
          const closeButton = document.getElementById(
            `${'closeButton' + index}`
          );
          if (closeButton) {
            closeButton.addEventListener('click', () => {
              infoWindow.close();
            });
          }
        });
      });

      this.circles.push(circle); // Store the circle if needed later
    });

    for (let i = 0; i < this.points.length - 1; i++) {
      const segmentPath = this.generateBezierCurve(
        this.points[i],
        this.points[i + 1]
      );

      new google.maps.Polyline({
        path: segmentPath,
        strokeColor: '#0D0709',
        strokeOpacity: 1,
        strokeWeight: 2,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW, // Arrow icon
              scale: 2, // Size of the arrow
              strokeColor: '#0D0709',
            },
            offset: '10%', // Position of the arrow (middle of line)
          },
        ],
        map: this.map,
      });
    }
  }

  generateCurvedPath(
    points: google.maps.LatLngLiteral[]
  ): google.maps.LatLngLiteral[] {
    const curvedPath: google.maps.LatLngLiteral[] = [];

    for (let i = 0; i < points.length - 1; i++) {
      const segment = this.generateBezierCurve(points[i], points[i + 1]);
      curvedPath.push(...segment);
    }

    return curvedPath;
  }

  generateBezierCurve(
    start: google.maps.LatLngLiteral,
    end: google.maps.LatLngLiteral
  ): google.maps.LatLngLiteral[] {
    const curve = [];
    const controlPoint = {
      lat: (start.lat + end.lat) / 2 + 5, // Adjust height of the curve
      lng: (start.lng + end.lng) / 2 + 5, // Adjust curvature
    };

    for (let t = 0; t <= 1; t += 0.02) {
      // Smaller step = smoother curve
      const lat =
        (1 - t) * (1 - t) * start.lat +
        2 * (1 - t) * t * controlPoint.lat +
        t * t * end.lat;
      const lng =
        (1 - t) * (1 - t) * start.lng +
        2 * (1 - t) * t * controlPoint.lng +
        t * t * end.lng;
      curve.push({ lat, lng });
    }

    return curve;
  }
}
