<div class="activity-feed-container">
  <div class="toggle-button-container">
    <button
      (click)="toggleCollapse()"
      class="toggle-button border-primary-color">
      <span class="material-icons">
        {{ collapsed ? 'arrow_back' : 'arrow_forward' }}
      </span>
    </button>
  </div>

  @if (!collapsed) {
    <!-- <app-activity-feed-filters (selectedFilters)="setFilters($event)"></app-activity-feed-filters> -->

    <p-card header="" (scroll)="onScroll($event)" class="activity-card">
      <div class="font-bold mb-5">Activity Feed</div>
      <div (scroll)="onScroll($event)" class="activity-list pb-[100px]">
        @for (activity of activityLogs$().payload; track $index) {
          <div class="activity-item items-center">
            <div class="activity-avatar">
              <img
                [src]="
                  activity?.user?.photo ?? bucketUrl + '/images/avatar.png'
                "
                class="w-[50px] h-[50px] object-cover img-style"
                [alt]="activity?.user?.firstName" />
            </div>

            <div class="activity-content">
              <div class="flex items-center justify-between">
                <span class="username">
                  {{
                    '@' + activity?.user?.firstName + activity?.user?.lastName
                  }}
                </span>
                <span class="timestamp">
                  {{ activity.createdAt | timeAgo }}
                </span>
              </div>

              <div class="activity-text">
                {{ activity.description }}
              </div>
            </div>
          </div>
        }
      </div>
    </p-card>
  }
</div>
