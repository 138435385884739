import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { HeatMapComponent } from '../../../components/charts/heat-map/heat-map.component';
import { Store } from '@ngxs/store';
import {
  GetExpirationAnalysis,
  GetLocationExpirationStats,
  ResetDashboardState,
} from '../../../../store/dashboard-store/dashboard.actions';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import { ButtonModule } from 'primeng/button';
import { DashboardFiltersComponent } from '../../../components/shared-components/dashboard-filters/dashboard-filters.component';
import {
  ExpirationAnalysis,
  LocationExpirationStats,
} from '../../../../store/dashboard-store/dashboard.model';
import { DonutChartComponent } from '../../../components/charts/donut-chart/donut-chart.component';
import { ExpirationBarChartComponent } from '../../../components/charts/expiration-bar-chart/expiration-bar-chart.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@Component({
  selector: 'app-product-expiration',
  standalone: true,
  imports: [
    HeatMapComponent,
    ButtonModule,
    DashboardFiltersComponent,
    DonutChartComponent,
    ExpirationBarChartComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './product-expiration.component.html',
  styleUrl: './product-expiration.component.scss',
})
export class ProductExpirationComponent implements OnInit, OnDestroy {
  productId: number | null = null;
  overlay: boolean = false;
  isLoading$: Signal<boolean> = this.store.selectSignal(
    DashboardStateSelectors.isLoading
  );
  locationExpirationStats$: Signal<LocationExpirationStats[]> =
    this.store.selectSignal(DashboardStateSelectors.getLocationExpirationStats);

  expirationAnalysis$: Signal<ExpirationAnalysis | null> =
    this.store.selectSignal(DashboardStateSelectors.getExpirationAnalysis);

  selectedFilters: any = {
    cteId: null,
    kdeId: null,
    kdeValue: null,
    startDate: null,
    endDate: null,
  };

  constructor(private store: Store) {}

  ngOnInit(): void {}

  setFilters(event: any) {
    if (event.productId) {
      this.overlay = true;
      this.productId = event.productId;
      delete event.productId;
      this.selectedFilters = event;
      this.getLocationExpirationStats();
      this.getExpirationAnalysis();
    } else {
      this.overlay = false;
      this.store.dispatch(new ResetDashboardState());
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetDashboardState());
  }

  getLocationExpirationStats() {
    this.store.dispatch(
      new GetLocationExpirationStats(this.productId!, this.selectedFilters)
    );
  }

  getExpirationAnalysis() {
    this.store.dispatch(
      new GetExpirationAnalysis(this.productId!, this.selectedFilters)
    );
  }
}
