<div class="w-full p-8">
  @if (sidebar.visible && (sidebar.type === 'ADD' || sidebar.type === 'EDIT')) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      (sidebarHidden)="sidebar.visible = false; resetRecall()"
      [title]="sidebar.title"
      [widthClass]="sidebar.width">
      <app-initiate-recall-form
        (actionSuccess)="
          sidebar.visible = false; resetRecall(); getRecallList()
        "></app-initiate-recall-form>
    </app-right-form-base>
  }
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
    <div class="d-flex">
      <p-button
        id="initiate-recall-button"
        appAccess="initial-recall"
        icon="pi pi-plus"
        label="Initiate New Recall"
        (onClick)="dialogVisible = true" />
    </div>
  </div>

  <app-data-table
    [allowedActions]="['DELETE', 'EDIT']"
    [first]="first"
    export="RECALL"
    [rows]="rows"
    [sortBy]="sortBy"
    [sortOrder]="sortOrder"
    [selectedFilters]="selectedFilters"
    [data$]="this.data$"
    [headers$]="this.headers$"
    [isProcessing$]="this.isProcessing$"
    [pagination$]="this.pagination$"
    (actionClickedEvent)="actionClicked($event)"
    (refreshEvent)="refresh($event)" />
</div>

<p-dialog [modal]="true" [(visible)]="dialogVisible" class="w-[25%]">
  <div class="text-center">
    <div class="mb-4">
      <span
        class="pi pi-exclamation-circle text-primary-color text-[50px]"></span>
    </div>
    <div class="text-lg font-bold mb-4">
      Please ensure you follow the steps below to successfully complete the
      product recall
    </div>
    <div class="text-left rounded-lg p-3 bg-primary-color-light mb-4">
      <p>
        <span
          class="text-primary-color pi pi-check-circle mr-2 font-bold"></span
        ><span>Related CTEs and KDEs for it</span>
      </p>
      <p>
        <span
          class="text-primary-color pi pi-check-circle mr-2 font-bold"></span
        ><span>Add Users and assign them these CTEs</span>
      </p>
      <p>
        <span
          class="text-primary-color pi pi-check-circle mr-2 font-bold"></span
        ><span
          >Recall Approving Authority is added within the Organization or
          not</span
        >
      </p>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <button
          pButton
          class="bg-slate-200 text-black border-slate-200"
          (click)="dialogVisible = false">
          Cancel
        </button>
        <button
          pButton
          class="bg-primary-color"
          (click)="dialogVisible = false; openSidebar()">
          Continue
        </button>
      </div>
    </div>
  </div>
</p-dialog>
