<div class="p-5 rounded-lg border-dashed border-2 bg-white cursor-pointer">
  <div class="text-center" (click)="fileInput.click()">
    <div>
      <span class="pi pi-upload text-2xl text-primary-color"></span>
    </div>
    <div class="font-bold text-md">Choose files to upload</div>
    <div><small>Supported Formats: PDF, PNG, JPG</small></div>
    <div><small>Max File Size: 5MB</small></div>
  </div>
  <input
    type="file"
    (change)="onFileSelected($event)"
    accept="image/png, image/jpeg, application/pdf"
    multiple
    hidden
    #fileInput />
  <div class="mt-4">
    @if (uploadedFiles$()[fileType] && uploadedFiles$()[fileType].length > 0) {
      <div class="flex items-center space-x-4 flex-wrap">
        <div
          *ngFor="let file of uploadedFiles$()[fileType]; index as i"
          class="relative group w-[150px] h-[150px]">
          @if (!file.uploading) {
            @if (isImage(file)) {
              <img
                [alt]="file.name"
                [src]="file.objectURL"
                class="w-full h-full object-cover rounded-md border border-gray-300" />
            } @else {
              <div class="w-full h-full flex items-center justify-center">
                <span class="pi pi-file-pdf text-[100px]"></span>
              </div>
            }
          }
          @if (file.uploading) {
            <div class="flex items-center justify-center h-full w-full">
              <p-progressSpinner class="loader"></p-progressSpinner>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
