import {
  Component,
  EventEmitter,
  Output,
  Signal,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { GetAllOrganizations } from '../../../../store/organizations-store/organizations.actions';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { Organization } from '../../../../store/organizations-store/organizations.model';
import { cloneDeep } from 'lodash';
import { AddAdminUser } from '../../../../store/users-store/user.actions';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-add-organization-admin-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    CommonModule,
    RightFormFooterComponent,
  ],
  templateUrl: './add-organization-admin-form.component.html',
  styleUrl: './add-organization-admin-form.component.scss',
})
export class AddOrganizationAdminFormComponent {
  form: FormGroup;
  passwordVisibility = false;
  confirmPasswordVisibility = false;
  @Output() actionSuccess = new EventEmitter<boolean>();

  organizations$: Signal<Organization[]> = this.store.selectSignal(
    OrganizationStateSelectors.getAllOrganizations
  );

  processing$: Signal<boolean> = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.store.dispatch(new GetAllOrganizations());
    this.form = this.fb.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        organizationId: ['', [Validators.required]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );

    this.form.get('password')!.valueChanges.subscribe(password => {
      this.validatePasswordStrength(password);
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.addOrganizationAdmin();
    }
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      confirmPassword?.setErrors({ mismatch: true });
    } else {
      confirmPassword?.setErrors(null);
    }
  }

  validatePasswordStrength(password: string) {
    this.passwordValidations.forEach(validation => {
      validation.valid = validation.regex.test(password);
    });
  }

  passwordValidations = [
    {
      message: 'Contains at least one uppercase character',
      regex: /[A-Z]/,
      valid: false,
    },
    {
      message: 'Contains at least one lowercase character',
      regex: /[a-z]/,
      valid: false,
    },
    {
      message: 'Contains at least one special character',
      regex: /[^a-zA-Z0-9]/,
      valid: false,
    },
    { message: 'Contains at least one number', regex: /[0-9]/, valid: false },
  ];

  addOrganizationAdmin() {
    if (this.form.invalid) return;
    const data = cloneDeep(this.form.value);
    delete data.confirmPassword;
    this.store.dispatch(new AddAdminUser(data)).subscribe(() => {
      this.form.reset();
      this.actionSuccess.emit(true);
    });
  }
}
