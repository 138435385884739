import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';
import {
  CreateQrOrderParams,
  DownloadQrOrderParams,
  QrOrderResponse,
  QrOrdersResponse,
} from './qr.model';

@Injectable({
  providedIn: 'root',
})
export class QrService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getQrOrders = (
    paginationParams: PaginationParams
  ): Observable<QrOrdersResponse> => {
    return this.http
      .get<QrOrdersResponse>(`${this.apiUrl}/api/qr-generate-orders`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        delay(1000),
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching QR orders: ' + error.message)
          );
        })
      );
  };

  public getQrOrder = (id: number): Observable<QrOrderResponse> => {
    return this.http
      .get<QrOrderResponse>(`${this.apiUrl}/api/qr-generate-orders/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching QR order: ' + error.message)
          );
        })
      );
  };

  public createQrOder = (
    payload: CreateQrOrderParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/qr-generate-orders`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public downloadQrOder = (
    payload: DownloadQrOrderParams
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/qr-order-downloads`,
        payload
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  };

  public uploadHistory(
    qrGenerateOrderId: number
  ): Observable<GenericApiResponse> {
    return this.http
      .get<GenericApiResponse>(
        `${this.apiUrl}/api/qr-data-imports/order/${qrGenerateOrderId}`
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  }

  public downloadQRTemplate = (
    qrGenerateOrderId: number
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/qr-data/${qrGenerateOrderId}/export`,
        {}
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  };

  public uploadQRTemplate = (
    qrGenerateOrderId: number,
    payload: any
  ): Observable<GenericApiResponse> => {
    return this.http
      .post<GenericApiResponse>(
        `${this.apiUrl}/api/qr-data/${qrGenerateOrderId}/import`,
        { s3FileUrl: payload.toString() }
      )
      .pipe(
        map(res => res),
        catchError(error => throwError(() => error))
      );
  };
}
