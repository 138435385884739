import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { DeviceAnalytics } from '../../../../store/dashboard-store/dashboard.model';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-pie-chart',
  imports: [CommonModule],
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked
{
  @Input() data: DeviceAnalytics[] = [];
  @Input() title: string = '';
  @ViewChild('piChart') piChartElement!: ElementRef;
  private root!: am5.Root;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (Array.isArray(this.data) && this.data.length > 0) {
        if (this.piChartElement) {
          this.initMap();
        } else {
          setTimeout(() => {
            if (this.piChartElement) {
              this.initMap();
            }
          });
        }
      } else {
        this.disposeChart();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.piChartElement && this.data && this.data.length > 0) {
      this.initMap();
    }
  }
  ngAfterViewChecked(): void {
    if (
      this.data &&
      this.data.length > 0 &&
      this.piChartElement &&
      !this.root
    ) {
      this.initMap();
    }
  }

  initMap(): void {
    if (this.piChartElement) {
      if (this.root) {
        this.root.dispose();
      }
      this.root = am5.Root.new(this.piChartElement.nativeElement);

      this.root.setThemes([am5themes_Animated.new(this.root)]);

      const chart = this.root.container.children.push(
        am5percent.PieChart.new(this.root, {
          layout: this.root.verticalLayout,
          width: am5.percent(100),
          height: am5.percent(80),
        })
      );

      const series = chart.series.push(
        am5percent.PieSeries.new(this.root, {
          valueField: 'scans',
          categoryField: 'device',
        })
      );

      series.data.setAll(this.data);

      const colorArray = [
        am5.color('#4763E4'),
        am5.color('#C0C3FF'),
        am5.color('#81a2bb'),
      ];

      series.slices.template.setAll({
        tooltipText: '',
      });

      series.slices.each((slice, i) => {
        slice.set('fill', colorArray[i % colorArray.length]);
        slice.set('stroke', am5.color('#FFFFFF'));
        slice.set('strokeWidth', 10);
        slice.set('strokeOpacity', 0.8);
      });

      chart.appear(1000, 100);
    }
  }

  disposeChart(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  ngOnDestroy(): void {
    this.disposeChart();
  }
}
