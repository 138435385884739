import {
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Store } from '@ngxs/store';
import { Role } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { TextTransformPipe } from '../../../pipes/text-transform.pipe';
import { Vendor } from '../../../../store/vendors-store/vendors.model';
import { VendorStateSelectors } from '../../../../store/vendors-store/vendors.selectors';
import { GetAllVendors } from '../../../../store/vendors-store/vendors.actions';
import { AddInvitation } from '../../../../store/invitations-store/invitation.actions';
import { AddInvitationParams } from '../../../../store/invitations-store/invitation.model';
import { MultiSelectModule } from 'primeng/multiselect';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-invite-vendor-user-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    TextTransformPipe,
    MultiSelectModule,
    RightFormFooterComponent,
  ],
  templateUrl: './invite-vendor-user-form.component.html',
  styleUrl: './invite-vendor-user-form.component.scss',
})
export class InviteVendorUserFormComponent {
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<void>();
  form: FormGroup;
  disabling = true;
  vendors$: Signal<Vendor[]> = this.store.selectSignal(
    VendorStateSelectors.getAllVendors
  );

  roles$: Signal<Role[]> = this.store.selectSignal(
    GeneralStateSelectors.getRoles
  );

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.store.dispatch(new GetAllVendors());
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      vendorId: [''],
      roleId: [[], [Validators.required]],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  action() {
    if (!this.form.valid) return;
    const values = this.form.value;
    if (this.type === 'INVITE') {
      const requestObj: AddInvitationParams = {
        email: values.email,
        roleIds: values.roleId.map((res: Role) => res.id),
      };
      if (values.vendorId) {
        requestObj.vendorId = values.vendorId;
      }
      this.disabling = false;
      this.store
        .dispatch(new AddInvitation(requestObj))
        .pipe(
          catchError(error => {
            this.disabling = true;
            return of(error);
          })
        )
        .subscribe(res => {
          this.disabling = true;
          if (!res.error) {
            this.form.reset();
            this.actionSuccess.emit();
          }
        });
    }
  }
}
