<div class="min-h-[90vh] p-4 custom-gradient rounded-xl">
  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Recall Initiated By</div>
    </div>
    <div class="col-span-2">
      <div class="flex items-center gap-4">
        <div>
          <img
            [src]="data?.createdBy?.photo ?? bucketUrl + '/images/avatar.png'"
            class="w-[50px] h-[50px] object-cover rounded-full" />
        </div>
        <div>
          <div class="mb-0 font-bold">{{ data?.createdBy?.firstName }}</div>
          <div>
            <small>{{ data?.createdBy?.email }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Recall Name</div>
    </div>
    <div class="col-span-2">
      <div>{{ data?.recallName }}</div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Severity Level</div>
    </div>
    <div class="col-span-2">
      <div>{{ data?.severityLevel }}</div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Authorized Users</div>
    </div>
    <div class="col-span-2">
      <div>{{ getAuthorizedUsers(data?.authorizedUsers) }}</div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Reason of Recall</div>
    </div>
    <div class="col-span-2">
      <div>
        {{ data?.recallReason }}
      </div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Description for Email & SMS</div>
    </div>
    <div class="col-span-2">
      <div>
        {{ data?.descriptionForEmailAndSms }}
      </div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Product Scan Message</div>
    </div>
    <div class="col-span-2">
      <div>
        {{ data?.productScanMessage }}
      </div>
    </div>
  </div>

  <div
    class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
    <div>
      <div class="font-bold">Attachments</div>
    </div>
    <div class="col-span-2">
      <div>
        @for (url of getAttachments(data?.attachments); track url) {
          <div>{{ url }}</div>
        }
      </div>
    </div>
  </div>

  <div>
    <p class="text-base block font-bold !mb-2">Reason / Comments</p>
    <form [formGroup]="form">
      <textarea
        pInputTextarea
        rows="5"
        class="w-full"
        [class.input-danger]="
          form.get('approvedOrRejectReason')?.invalid &&
          form.get('approvedOrRejectReason')?.touched
        "
        placeholder="Description for Email & SMS"
        formControlName="approvedOrRejectReason"></textarea>
      <app-error-message
        [control]="form.get('approvedOrRejectReason')"
        label="Reason / Comments"></app-error-message>
    </form>
  </div>

  <div class="mt-4">
    <div class="flex items-center gap-3 justify-end">
      <p-button
        [outlined]="true"
        (click)="action = 'REJECTED'; sendOtp()"
        [disabled]="form.invalid"
        >Reject</p-button
      >
      <p-button
        (click)="action = 'APPROVED'; sendOtp()"
        [disabled]="form.invalid"
        >Approve <span class="ml-3 pi pi-arrow-right"></span
      ></p-button>
    </div>
  </div>
</div>

<p-dialog
  (onHide)="onOtpDialogClose()"
  [modal]="true"
  [(visible)]="otpDialogVisible"
  class="w-[25%]">
  <div class="text-center">
    <div class="mb-4">
      <span
        class="pi pi-exclamation-circle text-primary-color text-[50px]"></span>
    </div>
    <div class="text-lg font-bold mb-4">
      Confirm your recall with Two-Factor Authentication
    </div>
    <div class="mb-4">
      Please enter the 4-digit code sent to your registered email address
    </div>
    <div class="mb-4 text-center">
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length: 4,
          inputClass: 'otp-box',
          allowNumbersOnly: true,
        }"></ng-otp-input>
    </div>
    <div class="flex w-full justify-between items-center mb-4">
      <div>
        <countdown
          #cd
          [config]="{ leftTime: timeLeft, format: 'm:ss' }"
          (event)="countdownEvent($event)" />
      </div>
      <div>
        <p-button
          class="!bg-none"
          label="Resend OTP"
          (click)="resendOtp()"
          [disabled]="resendDisabled" />
      </div>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <button
          pButton
          class="bg-slate-200 text-black border-slate-200"
          (click)="otpDialogVisible = false">
          Cancel
        </button>
        <button
          pButton
          [disabled]="!otp || otp.length < 4 || authProcessing$()"
          class="bg-primary-color"
          (click)="validateOtp()">
          Continue
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="submitDialogVisible" class="w-[25%]">
  <div class="text-center">
    <div class="mb-4">
      <span class="pi pi-verified text-primary-color text-[50px]"></span>
    </div>
    <div class="text-lg font-bold mb-4">
      Recall request has been {{ action }}
    </div>
    <div class="mb-4">
      Click on the button below to view the listing of recalls
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <button
          (click)="submitDialogVisible = false; actionSuccess.emit(true)"
          pButton
          class="bg-primary-color w-100">
          View All Recall Requests
        </button>
      </div>
    </div>
  </div>
</p-dialog>
