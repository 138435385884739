<div class="bulk-upload-container grid grid-cols-3 gap-4 p-4">
  <div class="col-span-2">
    <div class="download-div pb-2">
      <h2 class="text-lg text-black font-bold mb-2">Download Template</h2>
      <div class="download-template flex items-center border rounded mb-4">
        <div class="flex-shrink-0">
          <img [src]="'assets/csv_file.png'" alt="csv" class="w-10 h-12" />
        </div>
        <div class="flex-grow ml-[2.5rem]">
          <h2 class="text-black font-bold mb-1">Bulk Upload Template</h2>
          <p class="text-sm text-gray-600">
            Download a predefined CSV template, fill in the required data
            fields, and upload it to streamline bulk data entry efficiently.
          </p>
        </div>
        <div class="flex-shrink-0 ml-4">
          <p-button
            id="download"
            icon="pi pi-download"
            label="Download"
            (onClick)="downloadTemplate()">
          </p-button>
        </div>
      </div>
    </div>

    <div class="upload-div">
      <h2 class="text-lg text-black font-bold mb-2">Upload Template</h2>
      <div
        class="upload-template border-dashed flex items-center justify-center border-2 rounded p-5 text-center cursor-pointer"
        (click)="triggerFileUpload()">
        <div class="mr-2">
          <img
            [src]="'assets/upload_file.png'"
            alt="csv"
            class="w-[24px] h-[20px]" />
        </div>
        <div class="ml-2">
          <h2 class="text-lg font-semi-bold text-black m-0">Upload Template</h2>
          <p class="text-sm text-gray-600 m-0">Support only (CSV)</p>
        </div>
        <input
          type="file"
          accept=".csv"
          class="hidden"
          id="file-upload"
          #fileInput
          (change)="handleFileUpload($event)" />
      </div>
      @if (selectedFileName) {
        <div class="file-preview mt-4">
          <p class="text-gray-700">Selected File: {{ selectedFileName }}</p>
        </div>
      }
      @if (isUploading) {
        <div class="loader mt-4">
          <p class="text-gray-700">Uploading file... Please wait.</p>
        </div>
      }
    </div>
  </div>

  <div class="upload-history border rounded p-4">
    <h2 class="text-lg font-bold text-black flex justify-between items-center">
      <span>Upload History</span>
      <p-button
        icon="pi pi-refresh"
        label="Reload"
        [outlined]="true"
        (click)="fetchUploadHistory()"
        [disabled]="isLoadingHistory"
        [loading]="isLoadingHistory">
      </p-button>
    </h2>

    @if (isLoadingHistory) {
      <div class="loader">
        <p class="text-center text-gray-600">Loading upload history...</p>
      </div>
    } @else if (uploadHistory.length > 0) {
      <div>
        <ul>
          <li *ngFor="let history of uploadHistory" class="mb-2">
            <div class="flex justify-between items-center">
              <div>
                <p class="date-style">
                  {{ history.date | date: 'mediumDate' }}
                </p>
                <p class="description-style">{{ history.description }}</p>
                <p class="name-style">By {{ history.name }}</p>
                <span
                  [ngClass]="
                    history.status === 'COMPLETED'
                      ? 'success-button'
                      : 'error-button'
                  "
                  class="font-bold">
                  {{ history.status }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    } @else {
      <div class="no-history">
        <p class="text-center text-gray-600">No upload history found.</p>
      </div>
    }
  </div>
</div>
