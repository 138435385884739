import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  GetAllProducts,
  GetProductKdeValues,
} from '../../../../store/products-store/products.actions';
import { DropdownModule } from 'primeng/dropdown';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { ProductKdeValues } from '../../../../store/products-store/products.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { RecallFilters } from '../../../../store/recall-management-store/recall-management.model';

@Component({
  selector: 'app-product-filters',
  imports: [DropdownModule, CommonModule, FormsModule, MultiSelectModule],
  templateUrl: './product-filters.component.html',
  styleUrl: './product-filters.component.scss',
})
export class ProductFiltersComponent {
  @Output() selectedFilters = new EventEmitter();
  @Input() filters: RecallFilters = {
    product: null,
    cte: null,
    kde: null,
    kdeValues: [],
  };
  products$ = this.store.selectSignal(ProductStateSelectors.getAllProducts);
  productKdeValues$: Signal<ProductKdeValues | null> = this.store.selectSignal(
    ProductStateSelectors.getProductKdeValues
  );

  constructor(private store: Store) {
    this.getAllProducts();
  }

  getAllProducts() {
    this.store.dispatch(new GetAllProducts());
  }

  getProductKdeValues(kdeId: number) {
    this.store.dispatch(new GetProductKdeValues(kdeId));
  }

  applyFilter() {
    const selectedFilters: any = Object.fromEntries(
      Object.entries(this.filters).filter(([_, value]) => value !== null)
    );
    const selectedFiltersProcessed: any = {};
    Object.keys(selectedFilters).forEach(key => {
      if (key === 'kde') {
        selectedFiltersProcessed['kde'] = selectedFilters[key];
        if (selectedFilters[key]) {
          this.getProductKdeValues(selectedFilters[key].id);
        }
      }
      if (key === 'cte') {
        selectedFiltersProcessed['cte'] = selectedFilters[key];
      }
      if (key === 'product') {
        selectedFiltersProcessed['product'] = selectedFilters[key];
      }
      if (key === 'kdeValues') {
        selectedFiltersProcessed['kdeValues'] = selectedFilters[key];
      }
    });
    this.selectedFilters.emit(selectedFiltersProcessed);
  }
}
