import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
  selector: '[appPerfectScrollbar]',
})
export class PerfectScrollbarDirective implements AfterViewInit {
  private ps: PerfectScrollbar | null = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.ps = new PerfectScrollbar(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
  }
}
