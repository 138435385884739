import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { Role } from '../../../../store/roles-permission-store/roles-permission.model';
import { Store } from '@ngxs/store';
import { RolesPermissionStateSelectors } from '../../../../store/roles-permission-store/roles-permission.selectors';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { SearchProducts } from '../../../../store/products-store/products.actions';
import { DropdownModule } from 'primeng/dropdown';
import {
  AddRoleProduct,
  DeleteRoleProduct,
  GetRole,
  UpdateRole,
  UpdateRoleCte,
  UpdateRolePermission,
  UpdateRolePermissions,
  UpdateRoleProduct,
} from '../../../../store/roles-permission-store/roles-permission.actions';
import { UserRoles } from '../../../../store/users-store/user.model';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-add-role-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    TabViewModule,
    InputSwitchModule,
    DropdownModule,
    CommonModule,
    RightFormFooterComponent,
  ],
  templateUrl: './add-role-form.component.html',
  styleUrl: './add-role-form.component.scss',
})
export class AddRoleFormComponent implements OnChanges {
  @Input() id?: number;
  @Input() type?: string;
  @Input() data?: Role;
  @Output() actionSuccess = new EventEmitter<boolean>();

  role$: Signal<Role | null> = this.store.selectSignal(
    RolesPermissionStateSelectors.getRole
  );
  searchedProducts$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.searchProducts
  );
  processing$: Signal<boolean> = this.store.selectSignal(
    RolesPermissionStateSelectors.isProcessing
  );
  productStateLoading$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  userRole$: Signal<UserRoles> = this.store.selectSignal(
    UserStateSelectors.getUserRole
  );

  //Todo: Add proper types
  sections: string[] = [];
  groupedPermissions: any;
  assignedPermissions?: any[] = [];
  assignedCtes?: any[] = [];
  assignedProducts: any[] = [];
  selectedProduct: any = null;
  searchedProducts: any[] = [];

  form: FormGroup;
  showProductsTab = true;
  selectAllPermission = false;
  roleId!: number;
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    if (this.userRole$() === UserRoles.SUPER_ADMIN) {
      this.showProductsTab = false;
    }
    this.form = this.fb.group({
      name: [this.role$()?.name, [Validators.required]],
    });
    this.searchProducts('');
    effect(() => {
      if (this.role$()) {
        this.sections = Object.keys(this.role$()?.groupedPermissionsBySection);
        this.groupedPermissions = this.role$()?.groupedPermissionsBySection;
        this.assignedPermissions = this.role$()?.assignedPermissions;
        this.assignedCtes = this.role$()?.assignedCtes;
        this.assignedProducts = this.role$()!.assignedProducts;
        this.roleId = this.role$()!.id;
        this.form.patchValue({
          name: this.role$()?.name,
        });
        this.checkAllPermission();
      }
      if (this.searchedProducts$()) {
        this.searchedProducts = this.searchedProducts$().map(p => {
          return {
            ...p,
            disabled: this.assignedProducts.some(ap => ap.id === p.id),
          };
        });
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.updateRole();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['id'] &&
      changes['id'].currentValue !== changes['id'].previousValue
    ) {
      if (this.id) {
        this.getRole(this.id!);
      }
    }
  }

  getRole(id: number) {
    this.store.dispatch(new GetRole(id));
  }

  searchProducts(value: string) {
    this.store.dispatch(
      new SearchProducts({
        first: 0,
        rows: 10,
        sortBy: 'productName',
        sortOrder: 'ASC',
        filters: '',
        search: value,
      })
    );
  }

  addRoleProduct(productId: number) {
    const roleId = this.role$() ? this.role$()!.id : this.data?.id;
    if (roleId) {
      this.store
        .dispatch(
          new AddRoleProduct({
            allowed: true,
            roleId,
            productId: productId,
          })
        )
        .subscribe(() => {
          this.selectedProduct = null;
          this.actionSuccess.emit();
          this.store.dispatch(new GetRole(roleId));
        });
    }
  }

  updateRole() {
    if (!this.form.value.name) return;
    const roleId = this.role$() ? this.role$()!.id : this.data?.id;
    if (roleId) {
      this.store
        .dispatch(
          new UpdateRole(roleId, {
            name: this.form.value.name,
          })
        )
        .subscribe(() => {
          this.actionSuccess.emit();
          this.store.dispatch(new GetRole(roleId));
        });
    }
  }

  updatePermission(permission: any) {
    this.store
      .dispatch(
        new UpdateRolePermission(permission.RolePermissions.id, {
          allowed: permission.RolePermissions.allowed,
        })
      )
      .subscribe({
        next: () => {
          this.checkAllPermission();
        },
      });
  }

  updateCte(cte: any) {
    this.store.dispatch(
      new UpdateRoleCte(cte.RoleCte.id, {
        allowed: cte.RoleCte.allowed,
      })
    );
  }

  updateProduct(product: any) {
    this.store.dispatch(
      new UpdateRoleProduct(product.RoleProduct.id, {
        allowed: product.RoleProduct.allowed,
      })
    );
  }

  deleteProduct(product: any) {
    const roleId = this.role$() ? this.role$()!.id : this.data?.id;
    if (roleId) {
      this.store
        .dispatch(new DeleteRoleProduct(product.RoleProduct.id))
        .subscribe(() => {
          this.store.dispatch(new GetRole(roleId));
          this.actionSuccess.emit();
        });
    }
  }

  toggleAllPermissions(_selectAll: boolean) {
    Object.values(this.groupedPermissions).forEach((section: any) =>
      section.forEach(
        (item: any) => (item.RolePermissions.allowed = _selectAll)
      )
    );
    this.store.dispatch(
      new UpdateRolePermissions(this.roleId, { allowed: _selectAll })
    );
  }

  checkAllPermission() {
    this.selectAllPermission = Object.values(this.groupedPermissions).every(
      (section: any) =>
        section.every((item: any) => item.RolePermissions.allowed)
    );
  }
}
