import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  computed,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { Vendor } from '../../../../store/vendors-store/vendors.model';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  City,
  Country,
  Data,
  UploadedFile,
} from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { Store } from '@ngxs/store';
import { skipWhile, take } from 'rxjs';
import {
  AddUploadedFile,
  ClearUploadedFiles,
  GetCities,
} from '../../../../store/general-store/general.actions';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { DropdownModule } from 'primeng/dropdown';
import { ImageUploadComponent } from '../../shared-components/image-upload/image-upload.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import {
  CreateVendor,
  UpdateVendor,
} from '../../../../store/vendors-store/vendors.actions';
import { VendorStateSelectors } from '../../../../store/vendors-store/vendors.selectors';
import { ButtonModule } from 'primeng/button';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-add-vendor-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    DropdownModule,
    ImageUploadComponent,
    InputGroupModule,
    InputGroupAddonModule,
    ButtonModule,
    RightFormFooterComponent,
  ],
  templateUrl: './add-vendor-form.component.html',
  styleUrl: './add-vendor-form.component.scss',
})
export class AddVendorFormComponent implements OnChanges, OnDestroy {
  @Input() data?: Vendor;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<boolean>();
  isOther: boolean = false;

  form: FormGroup;
  countries: Country[] = [];
  organizationTypes$: Signal<Data[]> = this.store.selectSignal(
    GeneralStateSelectors.getOrganizationTypes
  );
  designations$: Signal<Data[]> = this.store.selectSignal(
    GeneralStateSelectors.getDesignations
  );

  designationsWithOthers$ = computed(() => {
    const designations = this.designations$();
    return [
      ...designations,
      { key: 'Others', value: 'Others' }, // Adjust structure as needed
    ];
  });
  cities$: Signal<City[]> = this.store.selectSignal(
    GeneralStateSelectors.getCities
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);
  processing$ = this.store.selectSignal(
    VendorStateSelectors.isVendorStoreProcessing
  );

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      gln: [
        '',
        [
          Validators.pattern(/^\d{13}$/), // GLN validation: exactly 13 numeric digits
        ],
      ],
      contactPerson: [''],
      phone: [
        '',
        [
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      vendorEmail: ['', [Validators.required, Validators.email]],
      address: [''],
      contactPersonDesignation: [null],
      logo: ['', [Validators.required]],
    });
    effect(() => {
      this.form.patchValue({
        logo: this.uploadedFiles$()['logo']
          ? this.uploadedFiles$()['logo'][0]?.objectURL
          : '',
      });
    });
    this.store
      .select(GeneralStateSelectors.getCountries)
      .pipe(skipWhile(res => !res))
      .pipe(take(1))
      .subscribe(res => {
        this.countries = res;
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.action();
    }
  }

  onDesginationChange(value: string): void {
    if (value === 'OTHERS') {
      this.isOther = true;
      this.cdr.detectChanges();
      this.form.get('contactPersonDesignation')?.setValue('');
    } else if (
      this.designationsWithOthers$().some(
        designation => designation.value === value
      )
    ) {
      this.isOther = false;
    }
  }

  onVendorTypeChange(value: string): void {
    if (value === 'OTHERS') {
      console.log('-----', value);
      this.isOther = true;
      this.cdr.detectChanges();
      this.form.get('type')?.setValue('');
    } else if (this.organizationTypes$().some(type => type.value === value)) {
      this.isOther = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data?.countryId) {
        this.getCities(this.data?.countryId);
      }
      if (this.data?.logo) {
        const uploadedFiles: UploadedFile = {
          objectURL: this.data?.logo,
        };
        this.store.dispatch(new AddUploadedFile(uploadedFiles, 'logo'));
      }
      this.form.patchValue({
        name: this.data?.name || '',
        type: this.data?.type || '',
        countryCode: this.data?.countryId || '',
        gln: this.data?.gln || '',
        city: this.data?.cityId || '',
        contactPerson: this.data?.contactPerson || '',
        phone: this.data?.phone || '',
        vendorEmail: this.data?.email || '',
        address: this.data?.businessAddress || '',
        contactPersonDesignation: this.data?.contactPersonDesignation || null,
        logo: this.data?.logo || '',
      });
    }
  }

  action() {
    if (!this.form.valid) return;
    const trimmedValue = this.form.get('name')?.value?.trim();
    this.form.get('name')?.setValue(trimmedValue);
    if (!trimmedValue) {
      return;
    }
    const values = this.form.value;
    if (this.type === 'ADD') {
      this.store
        .dispatch(
          new CreateVendor({
            businessAddress: values.address,
            type: values.type,
            gln: values.gln === '' ? null : values.gln,
            cityId: values.city,
            contactPerson: values.contactPerson,
            email: values.vendorEmail,
            logo: values.logo,
            name: values.name,
            phone: values.phone === '' ? null : values.phone,
            countryId: values.countryCode,
            contactPersonDesignation: values.contactPersonDesignation,
          })
        )
        .subscribe(() => {
          this.form.reset();
          this.store.dispatch(new ClearUploadedFiles());
          this.actionSuccess.emit(true);
        });
    } else if (this.type === 'EDIT' && this.id) {
      this.store
        .dispatch(
          new UpdateVendor(
            {
              businessAddress: values.address,
              type: values.type,
              gln: values.gln === '' ? null : values.gln,
              cityId: values.city,
              contactPerson: values.contactPerson,
              email: values.vendorEmail,
              logo: values.logo,
              name: values.name,
              phone: values.phone === '' ? null : values.phone,
              countryId: values.countryCode,
              contactPersonDesignation: values.contactPersonDesignation,
            },
            this.id
          )
        )
        .subscribe(() => {
          this.form.reset();
          this.actionSuccess.emit(true);
        });
    }
  }

  getCities(countryId: number) {
    this.store.dispatch(new GetCities(countryId));
  }

  getCountryCode(id: string) {
    if (id === '') {
      return '-';
    }
    return this.countries.filter(c => c.id === Number(id))[0]?.dialCode;
  }

  onCustomUpload() {
    console.log('imageUploadCalled');
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearUploadedFiles());
  }
}
