import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
  standalone: true,
})
export class CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null || isNaN(value)) {
      return '$0'; // Handle invalid input
    }

    // Divide by 100 to convert to dollars
    const amount = value / 100;

    // Check if amount is negative
    if (amount < 0) {
      return `- $${amount * -1}`; // Parentheses to indicate negative amount
    }

    // Return positive formatted amount
    return `$${amount}`;
  }
}
