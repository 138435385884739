<div class="flex justify-start sticky top-0">
  <div class="card qr-container">
    @if (!product) {
      <p class="text-center">Select a product</p>
    } @else {
      <div class="text-center mt-[20px]">
        @if (ctaConfig?.ctaLabelPosition === 'TOP') {
          <p>{{ ctaConfig?.ctaLabelText }}</p>
        }
        <ngx-qrcode-styling [config]="qrConfig"></ngx-qrcode-styling>
        @if (ctaConfig?.ctaLabelPosition === 'BOTTOM') {
          <p>{{ ctaConfig?.ctaLabelText }}</p>
        }
      </div>
    }
  </div>
</div>
