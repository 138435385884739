<p class="text-4xl font-semibold !mb-2">Welcome to {{ orgName }}</p>
<p class="text-base font-normal !mt-2">
  Thank you for accepting invitation, fill the following to proceed.
</p>
<form [formGroup]="form" autocomplete="off">
  <p class="text-base font-normal !mt-10 mb-1">First Name</p>
  <input
    type="text"
    pInputText
    formControlName="firstName"
    placeholder="James"
    class="w-full"
    [class.input-danger]="
      form.get('firstName')?.invalid && form.get('firstName')?.touched
    " />
  <app-error-message
    [control]="form.get('firstName')"
    label="First Name"></app-error-message>

  <p class="text-base font-normal mb-1">Last Name</p>
  <input
    type="text"
    pInputText
    formControlName="lastName"
    placeholder="McKenzie"
    class="w-full"
    [class.input-danger]="
      form.get('lastName')?.invalid && form.get('lastName')?.touched
    " />
  <app-error-message
    [control]="form.get('lastName')"
    label="Last Name"></app-error-message>

  <p class="text-base font-normal mb-1">Email</p>
  <input
    type="email"
    formControlName="email"
    placeholder="Ex. pat@example.com"
    pInputText
    class="w-full"
    [class.input-danger]="
      form.get('email')?.invalid && form.get('email')?.touched
    " />
  <app-error-message
    [control]="form.get('email')"
    label="Email"></app-error-message>

  <p class="text-base font-normal mb-1">Password</p>
  <p-iconField iconPosition="right">
    <p-inputIcon
      (click)="passwordVisibility = !passwordVisibility"
      [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
    <input
      [type]="passwordVisibility ? 'text' : 'password'"
      formControlName="password"
      placeholder="************"
      pInputText
      class="w-full"
      [class.input-danger]="
        form.get('password')?.invalid && form.get('password')?.touched
      " />
  </p-iconField>
  <app-error-message
    [control]="form.get('password')"
    label="Password"></app-error-message>

  <p class="text-base font-normal mb-1">Confirm Password</p>
  <p-iconField iconPosition="right">
    <p-inputIcon
      (click)="confirmPasswordVisibility = !confirmPasswordVisibility"
      [styleClass]="
        confirmPasswordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
      " />
    <input
      [type]="confirmPasswordVisibility ? 'text' : 'password'"
      formControlName="confirmPassword"
      placeholder="************"
      pInputText
      class="w-full"
      [class.input-danger]="
        form.get('confirmPassword')?.invalid &&
        form.get('confirmPassword')?.touched
      " />
  </p-iconField>
  <app-error-message
    [control]="form.get('confirmPassword')"
    label="Confirm Password"></app-error-message>

  <div class="text-xs font-normal">
    @for (validation of passwordValidations; track $index) {
      <p class="password-text flex items-center !mb-1">
        <span
          class="password-icon mr-2"
          [class.text-teal-700]="validation.valid"
          [class.text-red-600]="!validation.valid">
          @if (!validation.valid) {
            <i class="pi pi-times-circle"></i>
          } @else {
            <i class="pi pi-verified"></i>
          }
        </span>
        <span>
          {{ validation.message }}
        </span>
      </p>
    }
    <p class="password-text flex items-center !mb-1">
      <span
        class="password-icon mr-2"
        [class.text-teal-700]="
          !form.get('confirmPassword')?.hasError('mismatch')
        "
        [class.text-red-600]="
          form.get('confirmPassword')?.hasError('mismatch')
        ">
        <i
          class="pi"
          [ngClass]="{
            'pi-times-circle': form
              .get('confirmPassword')
              ?.hasError('mismatch'),
            'pi-verified': !form.get('confirmPassword')?.hasError('mismatch'),
          }"></i>
      </span>
      <span *ngIf="form.get('confirmPassword')?.hasError('mismatch')"
        >Passwords do not match</span
      >
      <span *ngIf="!form.get('confirmPassword')?.hasError('mismatch')"
        >Passwords are matching</span
      >
    </p>
  </div>
  <div class="terms-conditions-checkbox">
    <p-checkbox
      [binary]="true"
      inputId="termsConditions"
      (onChange)="toggleCheckbox()" />
    <label for="termsConditions">
      I agree to all the
      <a
        class="text-blue-600 underline"
        href="https://www.regenesis.ag/terms-and-conditions/"
        target="_blank"
        >Terms & Conditions</a
      >
      and
      <a
        class="text-blue-600 underline"
        href="https://www.regenesis.ag/privacy-policy/"
        target="_blank"
        >Privacy Policy</a
      >
    </label>
  </div>
</form>
<app-action-button
  [disabled]="!this.form.valid || !isChecked"
  [actionInProgress]="processing$()"
  (actionClicked)="signup()"
  label="Sign Up"></app-action-button>
