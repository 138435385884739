import { Injectable } from '@angular/core';
import Shepherd from 'shepherd.js';
import { Router } from '@angular/router';
import { isSuperAdmin } from './global.service';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../store/users-store/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class IntroService {
  private readonly tour: Shepherd.Tour;
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  constructor(
    private readonly router: Router,
    private store: Store
  ) {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: { enabled: true },
        classes: 'shepherd-theme-arrows',
        scrollTo: true,
      },
    });
  }

  startTour(): void {
    if (!isSuperAdmin(this.user$())) {
      localStorage.setItem('regen_tour_completed', 'true');
      this.tour.addStep({
        id: 'welcome-tour',
        text: 'Hi, welcome onboard the reGenesis Track and Trace integrated application. Let us show you where you can start!',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/settings/roles']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'settings-tour',
        text: 'You can access all your Users, Payments and Data elements setting here.',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/settings/roles']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'add-role-step',
        text: 'As an administrator, a user can add several roles based on the company policies, these roles are also applicable any 3rd party vendor coming onboard the reGenesis.',
        classes: 'custom-tooltip',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/settings/payment-methods']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'payment-tour',
        text: 'On the payment settings, you can buy subscriptions, add your credit cards, and view your subscription status. You can also download the subscription invoice.',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/settings/ctes-kdes']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'ctes-kdes-tour',
        text: 'As you can see, the system provides a list of already established CTEs (Critical Tracking Elements) and KDEs (Key Data Elements). Our implementation team or the integrated help menu can guide you on the definition and use of CTEs and KDEs.',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/settings/ctes-kdes']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'add-ctes-kdes-step',
        text: 'You can also create your own set of CTEs and KDEs which can be associated to any products you will create.',
        classes: 'custom-tooltip',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/users/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'add-user-step',
        text: 'Next step is to create users for your company. These users can be your own organization users or can be 3rd party vendors who play critical role in tracking and tracing of the products.',
        classes: 'custom-tooltip-2',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/vendors/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'add-vendor-step',
        text: 'Here you can add vendors as well, with all their details such as name, email address, country, city and their types, make sure to enter the 13-digit Global Tracking Number as well.',
        classes: 'custom-tooltip-2',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/products/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'add-product-step',
        text: 'You can add your product from here with data related to product name, its units, useful links and images.',
        classes: 'custom-tooltip-2',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/products/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'product-actions-step',
        text: 'Here you can see four action items on your product listing. You can generate a Edit the product details, you can delete a product (conditions apply), create a QR code design and you can also set up a Public Access Page for the QR code',
        classes: 'custom-tooltip-3',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/qr/qr-orders/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'qr-orders-tour',
        text: 'Here you can see the list of QR orders you have placed. These orders are based on the subscriptions you have purchased.',
        classes: 'custom-tooltip-4',
        buttons: [
          {
            text: 'Next',
            action: () => {
              this.tour.getCurrentStep()?.hide();
              this.router.navigate(['/qr/qr-orders/list']).then(() => {
                setTimeout(() => {
                  this.tour.next();
                }, 250);
              });
            },
          },
        ],
      });
      this.tour.addStep({
        id: 'create-qr-order-step',
        text: 'You can order the QR codes based on the batch sizes of the products. Once QR Code is ordered it is downloadable from the listing where it will be downloaded as a PDF file.',
        classes: 'custom-tooltip-2',
        buttons: [
          {
            text: 'Finish',
            action: this.tour.complete,
          },
        ],
      });
      this.tour.start();
    }
  }

  exitTour(): void {
    this.tour.complete();
  }
}
