@if (type) {
  <div class="mt-5">
    <div class="flex items-center justify-between mb-3">
      <div class="text-base font-bold">
        VALIDATIONS<span class="text-red-500">*</span>
      </div>
      <div class="flex items-center">
        <i class="pi pi-info-circle text-gray-500 mr-2"></i>
        <p class="text-sm text-gray-500">
          You can choose one, some, or all of the options.
        </p>
      </div>
    </div>
    <div [formGroup]="form">
      <div formGroupName="validations">
        @if (
          this.form.get('validations')?.get('mandatory')?.get('shouldDisplay')
            ?.value
        ) {
          <div formGroupName="mandatory">
            <div class="flex items-center justify-between">
              <span>Mandatory Field</span>
              <p-inputSwitch
                formControlName="enabled"
                class="mb-2"></p-inputSwitch>
            </div>
          </div>
        }
        @if (
          formType === 'ADD' ||
          (formType === 'EDIT' &&
            this.form.get('validations')?.get('format')?.get('shouldDisplay')
              ?.value)
        ) {
          <div formGroupName="format">
            @if (type !== 'RADIO' && type !== 'SELECT') {
              <div class="flex items-center justify-between">
                <span>Format OR Validation</span>
                <p-inputSwitch
                  formControlName="enabled"
                  class="mb-2"></p-inputSwitch>
              </div>
            }
            <div formGroupName="details">
              @if (
                this.form.get('validations')?.get('format')?.get('enabled')
                  ?.value
              ) {
                @if (type !== 'RADIO' && type !== 'SELECT') {
                  <ng-container>
                    <div class="mt-4 mb-4">
                      <div class="text-sm font-bold">
                        VALIDATION<span class="text-red-500">*</span>
                      </div>
                      @if (type === 'DECIMAL') {
                        <div class="flex items-center justify-between">
                          <div class="w-full">
                            <p-dropdown
                              formControlName="validationName"
                              optionValue="label"
                              [options]="options"
                              placeholder="Select Input Type"
                              [optionLabel]="'label'"
                              (onChange)="onDropdownChange($event)">
                              <ng-template let-item pTemplate="item">
                                <div class="flex flex-col">
                                  <span>{{ item.label }}</span>
                                  <span class="text-gray-400 text-sm">{{
                                    item.subLabel
                                  }}</span>
                                </div>
                              </ng-template>
                              <ng-template let-item pTemplate="selectedItem">
                                <span
                                  >{{ item?.label }} ({{ item.subLabel }})</span
                                >
                              </ng-template>
                            </p-dropdown>
                          </div>
                          @if (selectedOption?.label === 'Range') {
                            <div class="w-1/2 pl-2">
                              <div class="text-sm font-bold">
                                START<span class="text-red-500">*</span>
                              </div>
                              <input
                                pInputText
                                class="w-full"
                                placeholder="Start"
                                formControlName="rangeStartInput" />
                            </div>
                            <div class="w-1/2 pl-2">
                              <div class="text-sm font-bold">
                                END<span class="text-red-500">*</span>
                              </div>
                              <input
                                pInputText
                                class="w-full"
                                placeholder="End"
                                formControlName="rangeEndInput" />
                            </div>
                          }
                        </div>
                      } @else if (type === 'DATE') {
                        <div class="flex items-center justify-between">
                          <div class="w-full">
                            <p-dropdown
                              formControlName="validationName"
                              optionValue="label"
                              [options]="options"
                              placeholder="Select Input Type"
                              [optionLabel]="'label'"
                              (onChange)="onDropdownChange($event)">
                              <ng-template let-item pTemplate="item">
                                <div class="flex flex-col">
                                  <span>{{ item.label }}</span>
                                  <span class="text-gray-400 text-sm">{{
                                    item.subLabel
                                  }}</span>
                                </div>
                              </ng-template>
                              <ng-template let-item pTemplate="selectedItem">
                                <span
                                  >{{ item?.label }} ({{ item.subLabel }})</span
                                >
                              </ng-template>
                            </p-dropdown>
                          </div>
                          @if (selectedOption?.label === 'Format') {
                            <div class="w-full pl-2">
                              <div class="text-sm font-bold">
                                FORMAT<span class="text-red-500">*</span>
                              </div>
                              <p-calendar
                                formControlName="format"
                                class="w-70"
                                [iconDisplay]="'input'"
                                [showIcon]="true"
                                placeholder="DD-MM-YYYY"
                                selectionMode="single"
                                [readonlyInput]="true" />
                            </div>
                          }
                        </div>
                      } @else if (type === 'TIME') {
                        <div class="flex items-center justify-between">
                          <div class="w-full">
                            <p-dropdown
                              formControlName="validationName"
                              optionValue="label"
                              [options]="options"
                              placeholder="Select Input Type"
                              [optionLabel]="'label'"
                              (onChange)="onDropdownChange($event)">
                              <ng-template let-item pTemplate="item">
                                <div class="flex flex-col">
                                  <span>{{ item.label }}</span>
                                  <span class="text-gray-400 text-sm">{{
                                    item.subLabel
                                  }}</span>
                                </div>
                              </ng-template>
                              <ng-template let-item pTemplate="selectedItem">
                                <span
                                  >{{ item?.label }} ({{ item.subLabel }})</span
                                >
                              </ng-template>
                            </p-dropdown>
                          </div>
                          @if (selectedOption?.label === 'Format') {
                            <div class="w-full pl-2">
                              <div class="text-sm font-bold">
                                FORMAT<span class="text-red-500">*</span>
                              </div>
                              <p-dropdown
                                formControlName="format"
                                [options]="options"
                                placeholder="Select Input Type"
                                [optionLabel]="'label'">
                                <ng-template let-item pTemplate="item">
                                  <div class="flex flex-col">
                                    <span>{{ item.label }}</span>
                                    <span class="text-gray-400 text-sm">{{
                                      item.subLabel
                                    }}</span>
                                  </div>
                                </ng-template>
                                <ng-template let-item pTemplate="selectedItem">
                                  <span
                                    >{{ item?.label }} ({{
                                      item.subLabel
                                    }})</span
                                  >
                                </ng-template>
                              </p-dropdown>
                            </div>
                          }
                        </div>
                      } @else if (type === 'FILE') {
                        <div class="flex items-center justify-between">
                          <div class="w-full">
                            <p-dropdown
                              formControlName="validationName"
                              optionValue="label"
                              [options]="options"
                              placeholder="Select Input Type"
                              [optionLabel]="'label'"
                              (onChange)="onDropdownChange($event)">
                              <ng-template let-item pTemplate="item">
                                <div class="flex flex-col">
                                  <span>{{ item.label }}</span>
                                  <span class="text-gray-400 text-sm">{{
                                    item.subLabel
                                  }}</span>
                                </div>
                              </ng-template>
                              <ng-template let-item pTemplate="selectedItem">
                                <span
                                  >{{ item?.label }} ({{ item.subLabel }})</span
                                >
                              </ng-template>
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="mt-5 p-4 flex items-center justify-between">
                          <div class="w-full">
                            <p-slider
                              formControlName="max"
                              styleClass="w-85"
                              (onChange)="onSliderChange($event)"></p-slider>
                          </div>
                          <span class="ml-3 text-sm font-bold"
                            >{{ fileSize }} MB</span
                          >
                        </div>

                      } @else {
                        <p-dropdown
                          formControlName="validationName"
                          optionValue="label"
                          [options]="options"
                          placeholder="Select Input Type"
                          [optionLabel]="'label'"
                          (onChange)="onDropdownChange($event)">
                          <ng-template let-item pTemplate="item">
                            <div class="flex flex-col">
                              <span>{{ item.label }}</span>
                              <span class="text-gray-400 text-sm">{{
                                item.subLabel
                              }}</span>
                            </div>
                          </ng-template>
                          <ng-template let-item pTemplate="selectedItem">
                            <span>{{ item?.label }} ({{ item.subLabel }})</span>
                          </ng-template>
                        </p-dropdown>
                      }
                      <div class="text-sm mt-4 font-bold">
                        ERROR MESSAGE<span class="text-red-500">*</span>
                      </div>
                      <div class="relative w-full">
                        <input
                          pInputText
                          class="w-full pr-10"
                          placeholder="Type your error text here..."
                          formControlName="errorMessage"
                          maxlength="70"
                          (input)="updateCharCount($event)" />
                        <div
                          class="absolute right-2 bottom-3 text-sm text-gray-500">
                          {{ charCount }}/70
                        </div>
                      </div>
                    </div>
                  </ng-container>
                } @else {
                  <div class="mt-4 mb-4">
                    <div class="text-sm mt-4 font-bold">
                      ERROR MESSAGE<span class="text-red-500">*</span>
                    </div>
                    <div class="relative w-full">
                      <input
                        pInputText
                        class="w-full pr-10"
                        placeholder="Type your error text here..."
                        formControlName="errorMessage"
                        maxlength="50"
                        (input)="updateCharCount($event)" />
                      <div
                        class="absolute right-2 bottom-3 text-sm text-gray-500">
                        {{ charCount }}/50
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        }
        @if (
          this.form.get('validations')?.get('uniqueness')?.get('shouldDisplay')
            ?.value
        ) {
          <div formGroupName="uniqueness">
            <div class="flex items-center justify-between">
              <span>Uniqueness Validation</span>
              <p-inputSwitch
                formControlName="enabled"
                class="mb-2"></p-inputSwitch>
            </div>
            @if (
              this.form.get('validations')?.get('uniqueness')?.get('enabled')
                ?.value
            ) {
              <ng-container>
                <div class="mt-4 mb-4">
                  <div class="flex items-center justify-between">
                    <div class="flex-1 mr-2">
                      <div class="text-sm font-bold">
                        PREFIX<span class="text-red-500">*</span>
                      </div>
                      <input
                        pInputText
                        class="w-full"
                        placeholder="e.g. Name, Letter, Number"
                        formControlName="prefix" />
                    </div>
                    <div class="flex-1 ml-2">
                      <div class="text-sm font-bold">
                        SUFFIX<span class="text-red-500">*</span>
                      </div>
                      <input
                        pInputText
                        class="w-full"
                        placeholder="e.g. Name, Letter, Number"
                        formControlName="suffix" />
                    </div>
                  </div>
                </div>
              </ng-container>
            }
          </div>
        }
        @if (
          this.form.get('validations')?.get('length')?.get('shouldDisplay')
            ?.value
        ) {
          <div formGroupName="length">
            <div class="flex items-center justify-between">
              <span>Length Restrictions</span>
              <p-inputSwitch
                formControlName="enabled"
                class="mb-2"></p-inputSwitch>
            </div>
            @if (
              this.form.get('validations')?.get('length')?.get('enabled')?.value
            ) {
              <ng-container>
                <div class="mt-4 mb-4">
                  <div class="flex items-center justify-between">
                    <div class="flex-1 mr-2">
                      <div class="text-sm font-bold">
                        MIN WORD(S)<span class="text-red-500">*</span>
                      </div>
                      <div class="relative w-full">
                        <input
                          pInputText
                          class="w-full pr-10"
                          formControlName="min" />
                        <div
                          class="absolute right-2 bottom-3 text-sm text-gray-500">
                          Characters
                        </div>
                      </div>
                    </div>
                    <div class="flex-1 ml-2">
                      <div class="text-sm font-bold">
                        MAX WORD(S)<span class="text-red-500">*</span>
                      </div>
                      <div class="relative w-full">
                        <input
                          pInputText
                          class="w-full pr-10"
                          formControlName="max" />
                        <div
                          class="absolute right-2 bottom-3 text-sm text-gray-500">
                          Characters
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            }
          </div>
        }
      </div>
    </div>
    <!-- add multiple kdes using same form data -->
    <!-- <div class="flex items-center mt-6">
      <p-checkbox [binary]="true" />
      <span class="text-sm text-black font-semibold ml-2">CREATE ANOTHER KDE</span>
    </div> -->
  </div>
}
