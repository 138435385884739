<div class="grid grid-cols-6 gap-3">
  <div class="col-span-2 relative">
    <p-dropdown
      [(ngModel)]="filters.productId"
      [options]="products$()"
      optionLabel="productName"
      optionValue="id"
      optionDisabled="disabled"
      placeholder="Product Passport"
      class="w-full"
      [virtualScroll]="true"
      [itemSize]="30"
      filter="true"
      filterBy="productName" />
    @if (filters.productId) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-2 p-button p-button-rounded bg-white"
        (click)="clearFilter('productId')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <div class="col-span-2 relative">
    <input
      [(ngModel)]="filters.qrCode"
      pInputText
      class="w-full"
      placeholder="QR Code" />
    @if (filters.qrCode) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-2 p-button p-button-rounded bg-white"
        (click)="clearFilter('qrCode')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <div class="col-span-1 relative">
    <p-dropdown
      [(ngModel)]="filters.country"
      [options]="countries$()"
      optionLabel="name"
      placeholder="Country"
      class="w-full"
      (onChange)="getCities($event.value)"
      [virtualScroll]="true"
      [itemSize]="30"
      filter="true"
      filterBy="name" />
    @if (filters.country) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-2 p-button p-button-rounded bg-white"
        (click)="clearFilter('country')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <div class="col-span-1 relative">
    <p-dropdown
      [(ngModel)]="filters.city"
      [options]="cities$()"
      optionLabel="name"
      placeholder="City"
      class="w-full"
      filter="true"
      filterBy="name" />
    @if (filters.city) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-2 p-button p-button-rounded bg-white"
        (click)="clearFilter('city')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <div class="col-span-2 relative">
    <p-dropdown
      placeholder="Device Type"
      [(ngModel)]="filters.deviceType"
      [options]="deviceTypes"
      optionValue="value"
      optionLabel="key" />
    @if (filters.deviceType) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-2 p-button p-button-rounded bg-white"
        (click)="clearFilter('deviceType')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <div class="col-span-2 relative">
    <p-calendar
      [(ngModel)]="filters.dateRange"
      class="w-100"
      [iconDisplay]="'input'"
      [showIcon]="true"
      placeholder="Date Range"
      selectionMode="range" />
    @if (filters.dateRange?.length) {
      <button
        type="button"
        class="clear-button absolute top-[8px] right-[10px] p-button p-button-rounded bg-white"
        (click)="clearFilter('dateRange')">
        <i class="pi pi-times"></i>
      </button>
    }
  </div>

  <!-- Buttons -->
  <div>
    <p-button
      icon="pi pi-refresh"
      label="Reload Data"
      [outlined]="true"
      (click)="refreshData()"></p-button>
  </div>
  <div>
    <p-button (click)="applyFilter()">Apply Filter(s)</p-button>
  </div>
</div>
