<form [formGroup]="form" class="mb-4 full-height-form">
  <p class="text-base font-normal text-black mt-6 pb-2">CTE Title</p>
  <input
    pInputText
    class="w-full"
    [class.input-danger]="
      form.get('title')?.invalid && form.get('title')?.touched
    "
    placeholder="Enter Title"
    formControlName="title" />
  <app-error-message
    [control]="form.get('title')"
    label="CTE Title"></app-error-message>

  <p class="text-base font-normal text-black mt-6 pb-2">CTE Description</p>
  <textarea
    rows="5"
    class="w-full resize-none"
    pInputTextarea
    [class.input-danger]="
      form.get('description')?.invalid && form.get('description')?.touched
    "
    formControlName="description"
    placeholder="Add description here"></textarea>
  <app-error-message
    [control]="form.get('description')"
    label="CTE Description"></app-error-message>

  <p class="text-base font-normal text-black mt-6 pb-2">CTE Icon</p>
  <div class="mb-[15px]" [ngClass]="{ 'disabled-section': data?.isDefault }">
    <div class="grid grid-cols-10 gap-2">
      @for (icon of cteIcons$(); track icon) {
        <div
          class="icon-box"
          [ngClass]="{
            selected: selectedIcon === icon,
          }"
          (click)="selectedIcon = icon; uploadedIcon = ''">
          <img [src]="icon" [alt]="icon" />
        </div>
      }
    </div>
  </div>

  <p class="text-base font-normal text-black pb-2">Upload Custom CTE Icon</p>
  <div class="flex" [ngClass]="{ 'disabled-section': data?.isDefault }">
    <div class="upload-container mb-[15px]">
      <input
        #fileInput
        type="file"
        [id]="'icon-upload'"
        class="file-input"
        (change)="upload($event)"
        accept="image/svg+xml" />
      <label [for]="'icon-upload'" class="upload-label">
        <i class="pi pi-upload"></i>
        <p><small>Upload Custom Icon (.SVG)</small></p>
      </label>
    </div>
    @if (uploadedIcon !== '') {
      <div
        class="relative p-[50px] flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
        <img [src]="uploadedIcon" class="w-[100%]" />
        <span
          class="absolute top-[15px] right-[15px] transform translate-x-1/2 -translate-y-1/2 cursor-pointer bg-white text-red-600 rounded-full p-1"
          (click)="uploadedIcon = ''"
          title="Remove Icon">
          ✕
        </span>
      </div>
    }
    @if (this.isUploading) {
      <div
        class="flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
        <p-progressSpinner />
      </div>
    }
  </div>

  <div class="mb-[15px]">
    <label for="multiple">Enable Multiple KDE Records</label>
    <div class="mt-[5px]">
      <p-inputSwitch name="multiple" formControlName="multipleKDEsRecords" />
    </div>
  </div>
  <div class="mb-[15px]">
    <label for="location">Enable CTE Location</label>
    <div class="mt-[5px]">
      <p-inputSwitch
        name="location"
        (onChange)="toggleMapVisible($event)"
        formControlName="isMapVisible" />
    </div>
  </div>

  @if (form.get('isMapVisible')?.value) {
    <div class="mb-[10px]">
      <google-map
        *ngIf="mapSelectedPosition && mapCenter"
        height="300px"
        width="100%"
        [center]="mapCenter"
        [options]="mapOptions"
        (mapClick)="addMarker($event)">
        <map-marker
          [position]="mapSelectedPosition"
          [options]="mapOptions.animation">
        </map-marker>
      </google-map>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <p class="text-base font-normal text-black mt-6 pb-2">Latitude</p>
        <input
          pInputText
          class="w-full"
          (ngModelChange)="updateMapPosition()"
          [class.input-danger]="
            form.get('latitude')?.invalid && form.get('latitude')?.touched
          "
          formControlName="latitude" />
      </div>
      <div>
        <p class="text-base font-normal text-black mt-6 pb-2">Longitude</p>
        <input
          pInputText
          class="w-full"
          (ngModelChange)="updateMapPosition()"
          [class.input-danger]="
            form.get('longitude')?.invalid && form.get('longitude')?.touched
          "
          formControlName="longitude" />
      </div>
    </div>
  }
</form>
<app-right-form-footer
  [type]="type"
  [formValid]="
    (this.form.valid || data?.isDefault) &&
    (selectedIcon !== '' || uploadedIcon !== '')
  "
  [processing]="processing$()"
  (callAction)="action()"
  (callCancel)="actionSuccess.emit(false)">
</app-right-form-footer>
