<p-confirmDialog></p-confirmDialog>
@if (currUser$() && notAnAuthRoute()) {
  <app-header (toggleSidebar)="toggleSidebar()"></app-header>
  <div class="flex h-screen overflow-hidden bg-black">
    <!-- Sidebar -->
    <div class="flex-none">
      <app-sidebar [isExpanded]="isSidebarExpanded"></app-sidebar>
    </div>

    <!-- Main Content Area -->
    <div class="flex-auto flex flex-col bg-white overflow-hidden main-layout">
      @if (currUser$()?.currentPlan === null && showSubscriptionBanner) {
        <app-no-subscription-banner
          class="block px-8 mt-8"
          title="Subscribe to Continue!"
          description="Actions on this platform are available to subscribed users only. Please
        upgrade your account to proceed."></app-no-subscription-banner>
      }
      <div class="flex-auto overflow-auto pb-[100px]" appPerfectScrollbar>
        <router-outlet></router-outlet>
      </div>
    </div>

    @if (showActivityFeed) {
      <!-- Activity Feed -->
      <div
        class="flex-none h-full overflow-auto transition-all duration-300 bg-gray-100 shadow-lg"
        [ngClass]="{
          'w-16': isActivityFeedCollapsed,
          'w-80': !isActivityFeedCollapsed,
        }"
        appPerfectScrollbar>
        <app-activity-feed
          (toggleSidebar)="isActivityFeedCollapsed = $event"
          [collapsed]="isActivityFeedCollapsed"></app-activity-feed>
      </div>
    }
  </div>
} @else {
  <router-outlet></router-outlet>
}
