import { Component, Input, Signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DashboardFilters2Component } from '../../../components/shared-components/dashboard-filters-2/dashboard-filters-2.component';
import { ScanMapComponent } from '../../../components/charts/scan-map/scan-map.component';
import { Store } from '@ngxs/store';
import {
  DailyProductStats,
  DeviceAnalytics,
  LocationAnalytics,
  ProductAnalytics,
  ScanLocationAnalytics,
} from '../../../../store/dashboard-store/dashboard.model';
import { DashboardStateSelectors } from '../../../../store/dashboard-store/dashboard.selectors';
import {
  GetDailyProductStats,
  GetDeviceAnalytics,
  GetLocationAnalytics,
  GetProductAnalytics,
} from '../../../../store/dashboard-store/dashboard.actions';
import { PieChartComponent } from '../../../components/charts/pie-chart/pie-chart.component';
import { BarChartComponent } from '../../../components/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from '../../../components/charts/line-chart/line-chart.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-scan-statistics',
  templateUrl: './scan-statistics.component.html',
  styleUrls: ['./scan-statistics.component.scss'],
  imports: [
    ButtonModule,
    DashboardFilters2Component,
    ScanMapComponent,
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    ProgressSpinnerModule,
  ],
  standalone: true,
})
export class ScanStatistics {
  isLoading$: Signal<boolean> = this.store.selectSignal(
    DashboardStateSelectors.isLoading
  );
  locationAnalytics$: Signal<ScanLocationAnalytics | null> =
    this.store.selectSignal(DashboardStateSelectors.getLocationAnalytics);
  deviceAnalytics$: Signal<DeviceAnalytics[]> = this.store.selectSignal(
    DashboardStateSelectors.getDeviceAnalytics
  );
  productAnalytics$: Signal<ProductAnalytics[]> = this.store.selectSignal(
    DashboardStateSelectors.getProductAnalytics
  );
  dailyProductStats$: Signal<DailyProductStats[]> = this.store.selectSignal(
    DashboardStateSelectors.getDailyProductStats
  );
  selectedFilters: any = {};

  constructor(private readonly store: Store) {
    this.getLocationAnalytics();
    this.getDeviceAnalytics();
    this.getProductAnalytics();
    this.getDailyProductStats();
  }

  getLocationAnalytics() {
    this.store.dispatch(new GetLocationAnalytics(this.selectedFilters));
  }

  getDeviceAnalytics() {
    this.store.dispatch(new GetDeviceAnalytics(this.selectedFilters));
  }

  getProductAnalytics() {
    this.store.dispatch(new GetProductAnalytics(this.selectedFilters));
  }

  getDailyProductStats() {
    this.store.dispatch(new GetDailyProductStats(this.selectedFilters));
  }

  setFilters(event: any) {
    this.selectedFilters = event;
    this.getLocationAnalytics();
    this.getDeviceAnalytics();
    this.getProductAnalytics();
    this.getDailyProductStats();
  }
}
