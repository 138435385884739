import { Selector } from '@ngxs/store';
import { RecallManagementState } from './recall-management.store';
import { RecallManagementStateModel } from './recall-management.model';

export class RecallManagementStateSelectors {
  @Selector([RecallManagementState])
  static getRecalls(state: RecallManagementStateModel) {
    return state.recalls;
  }

  @Selector([RecallManagementState])
  static getRecallRequests(state: RecallManagementStateModel) {
    return state.recallRequests;
  }

  @Selector([RecallManagementState])
  static getRecall(state: RecallManagementStateModel) {
    return state.recall;
  }

  @Selector([RecallManagementState])
  static getPaginationData(state: RecallManagementStateModel) {
    return state.pagination;
  }

  @Selector([RecallManagementState])
  static isVendorStoreProcessing(state: RecallManagementStateModel) {
    return state.isProcessing;
  }

  @Selector([RecallManagementState])
  static getHeaders(state: RecallManagementStateModel) {
    return state.headers;
  }

  @Selector([RecallManagementState])
  static getFilters(state: RecallManagementStateModel) {
    return state.recallFilters;
  }

  @Selector([RecallManagementState])
  static countRecallItems(state: RecallManagementStateModel) {
    return state.countRecallItems;
  }
}
