import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { Recall } from '../../../../store/recall-management-store/recall-management.model';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import {
  ResendOtp,
  SendOtp,
  ValidateOtp,
} from '../../../../store/users-store/user.actions';
import { DialogModule } from 'primeng/dialog';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { NgOtpInputComponent } from 'ng-otp-input';
import { UpdateRecallStatus } from '../../../../store/recall-management-store/recall-management.actions';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-initiate-recall-view',
  imports: [
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    CountdownComponent,
    NgOtpInputComponent,
  ],
  templateUrl: './initiate-recall-view.component.html',
  styleUrl: './initiate-recall-view.component.scss',
})
export class InitiateRecallViewComponent {
  @ViewChild('cd', { static: false }) countdown: CountdownComponent | undefined;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:
    | NgOtpInputComponent
    | undefined;
  @Input() type?: string;
  @Input() data?: Recall;
  @Input() id?: number;
  @Output() actionSuccess = new EventEmitter<boolean>();

  bucketUrl = environment.bucketUrl;
  form: FormGroup;
  otpDialogVisible: boolean = false;
  submitDialogVisible: boolean = false;
  otp: string | null = null;
  timeLeft = 300;
  resendDisabled = true;
  action: string = '';

  currUser$ = this.store.selectSignal(UserStateSelectors.getUser);
  isOtpVerified$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isOtpVerified
  );
  authProcessing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      approvedOrRejectReason: ['', [Validators.required]],
    });

    effect(() => {
      if (this.isOtpVerified$() && this.id) {
        this.store
          .dispatch(
            new UpdateRecallStatus(this.id, {
              status: this.action,
              approvedOrRejectReason: this.form.value.approvedOrRejectReason,
              attachments: [],
              authorizedUsers: [],
            })
          )
          .subscribe(() => {
            this.otpDialogVisible = false;
            this.otp = null;
            this.ngOtpInput?.setValue('');
            this.submitDialogVisible = true;
          });
      }
    });
  }

  onOtpDialogClose() {
    this.otp = null;
    this.ngOtpInput?.setValue('');
    this.countdown?.stop();
  }

  getAuthorizedUsers(users: Recall['authorizedUsers'] | undefined) {
    if (!users) return;
    return users.map(u => u.user.firstName).join(', ');
  }

  getAttachments(attachments: Recall['attachments'] | undefined) {
    if (!attachments) return;
    return attachments.map(u => u.url.substring(u.url.lastIndexOf('/') + 1));
  }

  sendOtp() {
    this.countdown?.restart();
    this.store.dispatch(new SendOtp('RECALL')).subscribe(() => {
      this.otpDialogVisible = true;
    });
  }

  resendOtp() {
    this.resendDisabled = true;
    this.store.dispatch(new ResendOtp(this.currUser$()?.id ?? 1));
    this.countdown?.restart();
  }

  validateOtp() {
    if (this.otp && this.otp.length === 4) {
      this.store.dispatch(
        new ValidateOtp(this.currUser$()?.id ?? 0, parseInt(this.otp), 'RECALL')
      );
    }
  }

  onOtpChange(value: string) {
    if (value === this.otp) return;
    this.otp = value;
  }

  countdownEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.resendDisabled = false;
    }
  }
}
