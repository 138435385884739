import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { RecallManagementStateModel } from './recall-management.model';
import { RecallManagementService } from './recall-management.services';
import {
  CountRecallItems,
  CreateRecall,
  DeleteRecall,
  GetRecallList,
  GetRecallRequestList,
  ResetRecall,
  SetFilters,
  SetRecall,
  UpdateRecall,
  UpdateRecallStatus,
} from './recall-management.actions';

@State<RecallManagementStateModel>({
  name: 'RecallManagement',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    recalls: [],
    recallRequests: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    recallFilters: {
      product: null,
      cte: null,
      kde: null,
      kdeValues: [],
    },
    recall: null,
    countRecallItems: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class RecallManagementState {
  constructor(
    private recallManagementService: RecallManagementService,
    private notificationService: NotificationService
  ) {}

  @Action(GetRecallList)
  getRecallList(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecallList
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecalls(action.payload).pipe(
      tap(async res => {
        patchState({
          recalls: res.payload,
          recall: res.payload[0],
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetRecallRequestList)
  getRecallRequestList(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecallRequestList
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecallRequests(action.payload).pipe(
      tap(async res => {
        patchState({
          recallRequests: res.payload,
          recall: null,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(CreateRecall)
  createRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: CreateRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.createRecall(action.payload).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
          recall: res.payload.recallProduct,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(UpdateRecall)
  updateRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService
      .updateRecall(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isProcessing: false,
            recall: res.payload.recallProduct,
          });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }

  @Action(UpdateRecallStatus)
  updateRecallStatus(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallStatus
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService
      .updateRecallStatus(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isProcessing: false,
          });
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }

  @Action(DeleteRecall)
  deleteRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: DeleteRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.deleteRecall(action.id).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(SetFilters)
  setFilters(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: SetFilters
  ) {
    patchState({
      recallFilters: action.filters,
    });
  }

  @Action(ResetRecall)
  resetRecall({ patchState }: StateContext<RecallManagementStateModel>) {
    patchState({
      recallFilters: {
        product: null,
        cte: null,
        kde: null,
        kdeValues: [],
      },
      recall: null,
      countRecallItems: null,
    });
  }

  @Action(SetRecall)
  setRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: SetRecall
  ) {
    patchState({
      recall: action.recall,
    });
  }

  @Action(CountRecallItems)
  countRecallItems(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: CountRecallItems
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.countRecallItems(action.payload).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
          countRecallItems: res.payload.count,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }
}
