<div class="relative w-full p-8 bg-[#FAFAFA]">
  @if (isLoading$()) {
    <div
      class="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  }
  <div class="grid gap-4">
    <div class="flex items-center justify-between mb-5">
      <h3>Scan Statistics</h3>
      <p-button [outlined]="true">
        <span class="pi pi-download mr-3 pl-4"></span>
        <span class="pr-4">Export CSV</span>
      </p-button>
    </div>

    <app-dashboard-filters-2
      (selectedFilters)="setFilters($event)"></app-dashboard-filters-2>

    <div class="grid grid-cols-4 gap-4">
      <div class="p-0 col-span-1">
        <div>
          <div class="flex items-center justify-between mt-2 mb-2">
            <h3>Statistics</h3>
          </div>
          <div class="h-[500px] flex flex-col gap-5">
            <div class="bg-white p-5 rounded-lg flex-1 flex items-center">
              <div>
                <p class="m-0 mb-4">Total Scan Count</p>
                <p class="font-bold text-4xl m-0">
                  {{ locationAnalytics$()?.totalScans }}
                </p>
              </div>
            </div>
            <div class="bg-white p-5 rounded-lg flex-1 flex items-center">
              <div>
                <p class="m-0 mb-4">Unique QR Codes Scanned</p>
                <p class="font-bold text-4xl m-0">
                  {{ locationAnalytics$()?.totalUniqueQrCodes }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <app-scan-map [data]="locationAnalytics$()"></app-scan-map>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <app-pie-chart
        [title]="'Device Analytics'"
        [data]="deviceAnalytics$()"></app-pie-chart>
      <app-bar-chart
        [title]="'Product Analytics'"
        [data]="productAnalytics$()"></app-bar-chart>
    </div>

    <app-line-chart
      [title]="'Daily Scan Analytics'"
      [data]="dailyProductStats$()"></app-line-chart>
  </div>
</div>
