import { PaginationParams } from '../general-store/general.model';

const CONTEXT = '[Dashboard Store]';

export class GetActivityLogs {
  static readonly type = `${CONTEXT} Get Activity Logs`;
  constructor(public payload: PaginationParams) {}
}
export class GetChartsData {
  static readonly type = `${CONTEXT} Get Dashboard Chart Data `;
  constructor(public productId: number) {}
}
export class GetCteStatistics {
  static readonly type = `${CONTEXT} Get CTE Statistics`;
  constructor(public productId: number) {}
}
export class GetExpirationAnalysis {
  static readonly type = `${CONTEXT} Get Expiration Analysis`;
  constructor(
    public productId: number,
    public filters: any
  ) {}
}
export class GetQrMetrics {
  static readonly type = `${CONTEXT} Get QR Metrics`;
  constructor(
    public timeFrame: string,
    public productId: number | null
  ) {}
}
export class GetLocationAnalytics {
  static readonly type = `${CONTEXT} Get Location Analytics`;
  constructor(public filters: any) {}
}
export class GetDeviceAnalytics {
  static readonly type = `${CONTEXT} Get Device Analytics`;
  constructor(public filters: any) {}
}
export class GetProductAnalytics {
  static readonly type = `${CONTEXT} Get Product Analytics`;
  constructor(public filters: any) {}
}
export class GetDailyProductStats {
  static readonly type = `${CONTEXT} Get Daily Product Stats`;
  constructor(public filters: any) {}
}
export class GetLocationExpirationStats {
  static readonly type = `${CONTEXT} Get Location Expiration Stats`;
  constructor(
    public productId: number,
    public filters: any
  ) {}
}

export class GetTrackDashboardData {
  static readonly type = `${CONTEXT} Get Track Dashboard Data`;
  constructor(
    public filters: any,
    public productId: number
  ) {}
}

export class GetProductLocationStats {
  static readonly type = `${CONTEXT} Get Product Location Stats`;
  constructor(
    public filters: any,
    public productId: number
  ) {}
}

export class ResetDashboardState {
  static readonly type = `${CONTEXT} Reset Dashboard State`;
}
