import { Component, HostListener } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { ForgotPassword } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';

@Component({
  selector: 'app-forgot',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
  ],
  templateUrl: './forgot.component.html',
})
export class ForgotComponent {
  form: FormGroup;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.submitForgotPassword();
    }
  }

  submitForgotPassword() {
    if (this.form.invalid) return;
    this.store.dispatch(new ForgotPassword(this.form.get('email')?.value));
  }
}
