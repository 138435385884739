import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-right-form-footer',
  standalone: true,
  imports: [ActionButtonComponent, ButtonModule],
  templateUrl: './right-form-footer.component.html',
  styleUrl: './right-form-footer.component.scss',
})
export class RightFormFooterComponent {
  @Input() formValid?: boolean;
  @Input() notSubmitting = true; // Default to `true` if not provided
  @Input() type?: string;
  @Input() label = '';
  @Input() showPrimaryActionButton = true;
  @Input() processing = false;
  @Output() callAction = new EventEmitter<boolean>();
  @Output() callCancel = new EventEmitter<boolean>();

  constructor(private store: Store) {}
}
