<form [formGroup]="form" class="full-height-form">
  <p class="text-base font-normal">Title</p>
  <input
    pInputText
    class="w-full"
    [class.input-danger]="
      form.get('title')?.invalid && form.get('title')?.touched
    "
    placeholder="Enter Title"
    formControlName="title" />
  <app-error-message
    [control]="form.get('title')"
    label="KDE Title"></app-error-message>
  <p class="text-base font-normal">Description</p>
  <textarea
    rows="5"
    class="w-full resize-none"
    pInputTextarea
    [class.input-danger]="
      form.get('description')?.invalid && form.get('description')?.touched
    "
    formControlName="description"
    placeholder="Add description here"></textarea>
  <app-error-message
    [control]="form.get('description')"
    label="KDE Description"></app-error-message>

  <div class="flex space-x-12">
    <div>
      <p-checkbox binary="true" formControlName="gs1" value="GS1" />
      <label for="gs1" class="text-sm text-slate-600 font-semibold ml-3"
        >GS1</label
      >
    </div>
    <div>
      <p-checkbox binary="true" formControlName="fsma204" value="FSMA204" />
      <label for="fsma204" class="text-sm text-slate-600 font-semibold ml-3"
        >FSMA204</label
      >
    </div>
  </div>

  <div class="mt-11 mb-5 text-base font-normal text-black">
    Select KDE Inputs
  </div>

  <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
    <p-tabPanel
      header="Input"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'TEXT'">
      <div class="mt-6 w-100">
        <div class="mb-2">Types</div>
        <p-dropdown
          formControlName="inputType"
          [options]="inputOptions"
          placeholder="Select" />
      </div>
    </p-tabPanel>
    <p-tabPanel
      header="Date"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'DATE'">
      <div class="mt-6 w-100">
        <div class="mb-2">Types</div>
        <p-dropdown
          formControlName="inputType"
          [options]="dateInputOptions"
          placeholder="Select" />
      </div>
    </p-tabPanel>
    <p-tabPanel
      header="Radio Buttons"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'RADIO'">
      <div class="mt-6 flex items-center gap-2">
        <input
          pInputText
          class="w-full"
          placeholder="Key"
          formControlName="radioKey" />
        <input
          pInputText
          class="w-full"
          placeholder="Value"
          formControlName="radioValue" />
        <span
          class="pi pi-plus text-primary cursor-pointer"
          (click)="addRadioOption()"></span>
      </div>

      @for (radio of radioOptions; track radio; let i = $index) {
        <div class="flex items-center justify-between mt-[10px] mb-[10px]">
          <div>
            <p>Key: {{ radio.key }}</p>
            <p>Value: {{ radio.value }}</p>
          </div>
          <span
            class="pi pi-trash cursor-pointer text-danger"
            (click)="deleteRadioOption(i)"></span>
        </div>
      }
    </p-tabPanel>
    <p-tabPanel
      header="Dropdown"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'SELECT'">
      <div class="mt-6 flex items-center gap-2">
        <input
          pInputText
          class="w-full"
          placeholder="Key"
          formControlName="dropdownKey" />
        <input
          pInputText
          class="w-full"
          placeholder="Value"
          formControlName="dropdownValue" />
        <span
          class="pi pi-plus text-primary cursor-pointer"
          (click)="addDropdownOption()"></span>
      </div>

      @for (radio of dropdownOptions; track radio; let i = $index) {
        <div class="flex items-center justify-between mt-[10px] mb-[10px]">
          <div>
            <p>Key: {{ radio.key }}</p>
            <p>Value: {{ radio.value }}</p>
          </div>
          <span
            class="pi pi-trash cursor-pointer text-danger"
            (click)="deleteDropdownOption(i)"></span>
        </div>
      }
    </p-tabPanel>
    <p-tabPanel
      header="Location"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'LOCATION'">
      <p class="text-center text-primary">
        <i class="pi pi-map-marker text-[50px] mt-[20px]"></i>
      </p>
    </p-tabPanel>
    <p-tabPanel
      header="Attachment"
      [disabled]="type === 'EDIT' && form.value.inputType !== 'FILE'">
      <p class="text-center text-primary">
        <i class="pi pi-paperclip text-[50px] mt-[20px]"></i>
      </p>
    </p-tabPanel>
  </p-tabView>

  <app-validation
    [form]="form"
    [type]="form.get('inputType')?.value"
    [formType]="type"
    [options]="getOptionsForInputType(form.get('inputType')?.value)"
    [charCount]="charCount">
  </app-validation>
</form>
<app-right-form-footer
  [type]="type"
  [formValid]="this.form.valid && !isLoading"
  [notSubmitting]="disabling"
  [processing]="processing$()"
  (callAction)="action()"
  (callCancel)="actionSuccess.emit(undefined)">
</app-right-form-footer>
