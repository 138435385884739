import { PaginationParams } from '../general-store/general.model';
import {
  CountRecallItemsRequest,
  CreateRecallRequest,
  Recall,
  UpdateRecallStatusRequest,
} from './recall-management.model';

const CONTEXT = '[RecallManagementStore]';

export class GetRecallList {
  static readonly type = `${CONTEXT} Get Recall List`;
  constructor(public payload: PaginationParams) {}
}

export class GetRecallRequestList {
  static readonly type = `${CONTEXT} Get Recall Request List`;
  constructor(public payload: PaginationParams) {}
}

export class CreateRecall {
  static readonly type = `${CONTEXT} Create Recall`;
  constructor(public payload: CreateRecallRequest) {}
}
export class UpdateRecall {
  static readonly type = `${CONTEXT} Update Recall`;
  constructor(
    public id: number,
    public payload: CreateRecallRequest
  ) {}
}

export class UpdateRecallStatus {
  static readonly type = `${CONTEXT} Update Recall Status`;
  constructor(
    public id: number,
    public payload: UpdateRecallStatusRequest
  ) {}
}

export class DeleteRecall {
  static readonly type = `${CONTEXT} Delete Recall`;
  constructor(public id: number) {}
}

export class SetFilters {
  static readonly type = `${CONTEXT} Set Filters`;
  constructor(public filters: any) {}
}

export class ResetRecall {
  static readonly type = `${CONTEXT} Reset Recall`;
}

export class SetRecall {
  static readonly type = `${CONTEXT} Set Recall`;
  constructor(public recall: Recall) {}
}

export class CountRecallItems {
  static readonly type = `${CONTEXT} Count Recall Items`;
  constructor(public payload: CountRecallItemsRequest) {}
}
