import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-expiration-bar-chart',
  imports: [CommonModule],
  templateUrl: './expiration-bar-chart.component.html',
  styleUrl: './expiration-bar-chart.component.scss',
})
export class ExpirationBarChartComponent implements OnDestroy, OnChanges {
  @Input({ required: true }) data: any[] = [];
  @Input({ required: true }) title: string = '';
  @Input() overlay: boolean = false;

  private root!: am5.Root;
  private chart!: am5xy.XYChart;
  private xAxis!: any;
  private yAxis!: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.initMap();
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  initMap() {
    if (this.root) {
      this.root.dispose();
    }
    this.root = am5.Root.new('expirationBarChart');

    this.root.setThemes([am5themes_Animated.new(this.root)]);

    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 20, // Adjust grid distance for better spacing
      cellStartLocation: 0.1, // Align start of bars
      cellEndLocation: 0.9, // Align end of bars
    });

    xRenderer.grid.template.setAll({
      visible: false, // Disable gridlines
    });

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'country',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    this.xAxis.get('renderer').labels.template.setAll({
      fontSize: 14,
    });

    this.xAxis.data.setAll(this.data);

    const yRenderer = am5xy.AxisRendererY.new(this.root, {});

    yRenderer.grid.template.setAll({
      visible: false, // Disable gridlines
    });

    // Create Y-axis (values)
    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: yRenderer,
        min: 0,
        strictMinMax: true,
      })
    );

    this.yAxis.get('renderer').labels.template.setAll({
      fontSize: 14,
      paddingLeft: -15,
      paddingRight: 15,
    });

    this.createSeries('Safe', 'safe', '#16A34A'); // Green
    this.createSeries('Expiring', 'expiring', '#FDE047'); // Yellow
    this.createSeries('Expired', 'expired', '#DC2626'); // Red

    // Add legend
    this.chart.children.push(am5.Legend.new(this.root, {}));

    // Add cursor
    this.chart.set('cursor', am5xy.XYCursor.new(this.root, {}));
  }

  // Create series for each field (safe, expiring, expired)
  createSeries(name: string, field: string, color: string) {
    const series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: field,
        categoryXField: 'country',
        stacked: true,
      })
    );

    series.columns.template.setAll({
      tooltipText: `{name}: {valueY}`,
      fill: am5.color(color),
      stroke: am5.color('#FFFFFF'),
      strokeWidth: 5,
      cornerRadiusTL: 10, // Rounded top-left corner
      cornerRadiusTR: 10, // Rounded top-right corner
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    series.data.setAll(this.data);

    series.appear();
  }
}
