import { Component, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import {
  Header,
  Pagination,
  SidebarData,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../store/general-store/general.model';
import { DataTableComponent } from '../../../components/shared-components/data-table/data-table.component';
import { Recall } from '../../../../store/recall-management-store/recall-management.model';
import { RecallManagementStateSelectors } from '../../../../store/recall-management-store/recall-management.selectors';
import {
  DeleteRecall,
  GetRecallList,
  ResetRecall,
  SetFilters,
  SetRecall,
} from '../../../../store/recall-management-store/recall-management.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { DialogModule } from 'primeng/dialog';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { InitiateRecallFormComponent } from '../../../components/form-components/initiate-recall-form/initiate-recall-form.component';

@Component({
  selector: 'app-recall-list',
  imports: [
    ButtonModule,
    DataTableComponent,
    DialogModule,
    RightFormBaseComponent,
    InitiateRecallFormComponent,
  ],
  templateUrl: './recall-list.component.html',
  styleUrl: './recall-list.component.scss',
})
export class RecallListComponent {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-80',
  };
  selectedFilters: Record<string, any> = {};
  dialogVisible: boolean = false;

  data$: Signal<Recall[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecalls
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    RecallManagementStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    RecallManagementStateSelectors.isVendorStoreProcessing
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getHeaders
  );

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.getRecallList();
  }

  resetRecall() {
    this.store.dispatch(new ResetRecall());
  }

  openSidebar() {
    this.sidebar = {
      visible: true,
      title: 'Initiate New Recall',
      type: 'ADD',
      width: 'w-80',
    };
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getRecallList();
  }

  getRecallList() {
    this.store.dispatch(
      new GetRecallList({
        first: 0,
        rows: 5,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  actionClicked(action: TableActionEvent) {
    if (action.event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${action.event} RECALL`,
        message: `Are you sure want to delete this recall?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(new DeleteRecall(action.id)).subscribe(() => {
            this.getRecallList();
          });
        },
      });
    } else if (action.event === 'EDIT') {
      this.store.dispatch(new SetRecall(action.data));
      this.store.dispatch(new SetFilters(action.data.filters)).subscribe(() => {
        this.sidebar = {
          visible: true,
          title: 'Edit Recall',
          id: action.id,
          data: action.data,
          type: 'EDIT',
          width: 'w-80',
        };
      });
    }
  }
}
