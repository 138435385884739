import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { Store } from '@ngxs/store';
import { SliderModule } from 'primeng/slider';

@Component({
  selector: 'app-validation',
  standalone: true,
  imports: [
    InputSwitchModule,
    DropdownModule,
    CheckboxModule,
    InputTextModule,
    ReactiveFormsModule,
    CalendarModule,
    SliderModule,
  ],
  templateUrl: './validation.component.html',
})
export class ValidationComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() type!: string;
  @Input() formType!: string | undefined;
  @Input() options!: any[];
  @Input() charCount = 0;
  @Output() remove = new EventEmitter<void>();
  fileSize: number = 0;
  selectedOption: any;
  constructor(private readonly store: Store) {}

  ngOnInit(): void {}

  get formatDetails(): FormGroup {
    return this.form.get('format')?.get('details') as FormGroup;
  }
  onSliderChange(event: any): void {
    this.fileSize = event.value;
  }

  updateCharCount(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.charCount = input.value.length;
  }
  onDropdownChange(event: any): void {
    const selectedValue = event?.value || event?.option?.value;
    this.selectedOption = this.options.find(
      option =>
        option.label === selectedValue.label &&
        option.subLabel === selectedValue.subLabel
    );
    console.log('Selected Option:', this.selectedOption);
  }
}
