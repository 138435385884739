<div class="text-center mt-3 relative">
  @if (isInvalidUrl) {
    <div class="inset-0 flex justify-center items-center h-[250px] w-[100%]">
      <div>
        <span class="pi pi-file-excel text-[35px] block mb-2"></span>
        <span class="block"><small>Invalid URL</small></span>
      </div>
    </div>
  } @else {
    @if (thumbnailUrl) {
      <img [src]="thumbnailUrl" class="w-[100%] h-[250px] object-cover" />

      <div class="absolute inset-0 flex justify-center items-center">
        <a
          [href]="videoUrl"
          target="_blank"
          class="bg-black text-white rounded-full w-16 h-16 flex justify-center items-center text-2xl hover:scale-110 transition-transform no-underline">
          <span class="pi pi-play-circle text-[25px]"></span>
        </a>
      </div>
    } @else {
      <div class="inset-0 flex justify-center items-center h-[250px] w-[100%]">
        <p-progressSpinner />
      </div>
    }
  }
</div>
