import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
} from '@angular/core';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  GeneratePresignedUrl,
  RemoveUploadedFile,
} from '../../../../store/general-store/general.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-document-upload',
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './document-upload.component.html',
  styleUrl: './document-upload.component.scss',
})
export class DocumentUploadComponent implements OnChanges {
  @Input() singleMode = false;
  @Input() fileType = '';
  @Output() onUpload = new EventEmitter<UploadedFile[]>();

  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  constructor(private store: Store) {
    effect(() => {
      if (
        this.uploadedFiles$()[this.fileType] &&
        this.uploadedFiles$()[this.fileType].length > 0
      ) {
        this.onUpload.emit(this.uploadedFiles$()[this.fileType]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      const files = Array.from(input.files);
      files.forEach(file => {
        if (this.isValidFile(file)) {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            files.forEach(file => {
              this.store.dispatch(
                new GeneratePresignedUrl(file, this.fileType)
              );
            });
          };
          reader.readAsDataURL(file);
        } else {
          alert(`Invalid file: ${file.name}`);
        }
      });
    }
  }

  isValidFile(file: File): boolean {
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    return file.size <= maxSize && allowedTypes.includes(file.type);
  }

  isImage(file: UploadedFile): boolean {
    if (file.objectURL) {
      return this.getFileTypeFromUrl(file.objectURL) === 'pdf' ? false : true;
    } else {
      return false;
    }
  }

  getFileTypeFromUrl(url: string): string {
    return url.split('.').pop()?.toLowerCase() || 'unknown';
  }

  removeFile(file: UploadedFile): void {
    this.store.dispatch(new RemoveUploadedFile(file));
  }
}
