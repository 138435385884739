<div class="w-100 bg-white rounded-xl">
  <div class="flex items-center justify-start gap-2">
    <div
      class="m-3 border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      <p class="font-bold text-primary-color mr-3">
        <small>Customized Filters</small>
      </p>
    </div>
    <div
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @if (!filters.product) {
        <p-dropdown
          [options]="products$()"
          [(ngModel)]="filters.product"
          optionLabel="productName"
          [filter]="true"
          filterBy="productName"
          placeholder="Product Passport"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-product #item>
            <div class="flex items-center gap-2">
              <div>{{ product?.productName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xs">Product Passport</div>
            <div class="mr-3 font-bold">
              <small>{{ filters.product.productName }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.product = null;
                filters.dateRange = [];
                filters.cte = null;
                filters.kde = null;
                filters.startDate = null;
                filters.endDate = null;
                applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div
      *ngIf="filters.product"
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @if (!filters.startDate || !filters.endDate) {
        <p-calendar
          [(ngModel)]="filters.dateRange"
          [iconDisplay]="'input'"
          [showIcon]="true"
          placeholder="Date Range"
          selectionMode="range"
          (ngModelChange)="setDateFilter($event)"
          [readonlyInput]="true"
          (ngModelChange)="applyFilter()" />
      } @else {
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xs">Date Range</div>
            <div class="mr-3 font-bold">
              <small>{{ filters.startDate }} - {{ filters.endDate }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.dateRange = [];
                filters.startDate = null;
                filters.endDate = null;
                filters.cte = null;
                filters.kde = null;
                applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div
      *ngIf="filters.product && filters.startDate && filters.endDate"
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @if (!filters.cte) {
        <p-dropdown
          [options]="filters!.product!.productCtes"
          [(ngModel)]="filters.cte"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          placeholder="CTE"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-cte #item>
            <div class="flex items-center gap-2">
              <div>{{ cte?.title }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xs">CTE</div>
            <div class="mr-3 font-bold">
              <small>{{ filters!.cte!.title }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.cte = null; filters.kde = null; applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div
      *ngIf="filters.cte && filters.product && filters.dateRange"
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @if (!filters.kde) {
        <p-dropdown
          [options]="filters!.cte!.productKdes"
          [(ngModel)]="filters.kde"
          optionLabel="title"
          [filter]="true"
          filterBy="title"
          placeholder="KDE"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-kde #item>
            <div class="flex items-center gap-2">
              <div>{{ kde?.title }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xs">KDE</div>
            <div class="mr-3 font-bold">
              <small>{{ filters!.kde!.title }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="
                filters.kde = null; filters.kdeValue = null; applyFilter()
              "></span>
          </div>
        </div>
      }
    </div>
    <div
      *ngIf="filters.cte && filters.product && filters.dateRange && filters.kde"
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @if (!filters.kdeValue) {
        <p-dropdown
          [options]="productKdeValues$()?.values"
          [(ngModel)]="filters.kdeValue"
          optionLabel="value"
          optionValue="value"
          [filter]="true"
          filterBy="value"
          [placeholder]="productKdeValues$()?.kde?.title"
          class="w-full md:w-56"
          (ngModelChange)="applyFilter()">
          <ng-template let-value #item>
            <div class="flex items-center gap-2">
              <div>{{ value.value }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      } @else {
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xs">{{ (productKdeValues$()?.kde)!.title }}</div>
            <div class="mr-3 font-bold">
              <small>{{ filters!.kdeValue }}</small>
            </div>
          </div>
          <div class="mr-3">
            <span
              class="pi pi-times text-xs cursor-pointer"
              (click)="filters.kdeValue = null; applyFilter()"></span>
          </div>
        </div>
      }
    </div>
  </div>
</div>
